import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { CorporateConfig } from 'src/app/corporate.config';
import { HelpersService } from 'src/app/helpers/helpers.service';

@Component({
  selector: 'app-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styleUrls: ['./accessibility-statement.component.css']
})
export class AccessibilityStatementComponent implements OnInit {

  pageContent: string;

  constructor(
    private helpersService: HelpersService,
    private metaService: Meta,
    private titleService: Title
) { 
    this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.accessibilityStatementTitle));
    this.metaService.updateTag({name: 'description', content: CorporateConfig.accessibilityStatementDescription});
}

  ngOnInit() {
    this.pageContent = this.helpersService.getPageContent(CorporateConfig.accessibilityStatementPageContent);
  }

}
