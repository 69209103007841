import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorageService } from '../services/local-storage.service';
import { CurrentUser } from '../models/current-user.model';
import { AppConfig } from '../app.config';
import { HelpersService } from '../helpers/helpers.service';
import { SkeletonJobAlert } from '../components/search/jbe-custom-modal/models';

@Injectable()
export class AuthenticationService {

    public token: string;
    public user: string;

    constructor(
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService,
        protected helper: HelpersService
    ) {
        // set token if saved in local storage
        if (typeof window !== 'undefined') {
            if (this.localStorageService.isUserStillActive()) {
                this.token = localStorageService.getCurrentUser().token;
            }
        }
    }

    login(username: string, password: string): Observable<any> {

        //const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = new HttpResponse({ headers: headers });

        const body = `{"username": "${username}","password": "${password}"}`;
      
        const url = AppConfig.getUrl('AUTH.TOKEN');

        return this.httpClient.post<CurrentUser>(url, body, options).pipe(
            map((res: CurrentUser) => {

                this.localStorageService.setCurrentUser(res);
                this.token = res.token;
            }));
    }

    logout() {
        if (typeof window !== 'undefined') {

            // remove user from local storage to log user out
            this.localStorageService.clear();
        }
    }

    emailVerifiy(userId: string, code: string): Promise<boolean> {
        const xcode: string = encodeURI(code);
        const url = AppConfig.getUrl('Confirm.Candidate') + '?userId=' + userId + '&code=' + xcode;
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };

        return this.httpClient.get<boolean>(url, options).toPromise();
    }

    changeEmailVerifiy(userId: string, code: string): Promise<boolean> {
        const xcode: string = encodeURI(code);
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };

        return this.httpClient.get<boolean>(AppConfig.getUrl(
            'Confirm.EmailChange') + '?userId=' + userId + '&code=' + xcode, options).toPromise();
    }

    resendVerificationEmail(userId: string, email: string) {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('ResendVerificationEmail.Candidate') + '?userId=' + userId + '&email=' + email;

        return this.httpClient.get<boolean>(url, options).toPromise();
    }

    resendVerificationEmailAndCreateJobAlert(dto: SkeletonJobAlert, userId: string, email: string) {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('ResendVerificationEmailAndCreateJobAlert.Candidate') + '?userId=' + userId + '&email=' + email;

        return this.httpClient.post<boolean>(url, dto, options).toPromise();
    }

    resendChangeEmailVerification(userId: string) {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('Candidate.ResendChangeEmailVerification') + '?userId=' + userId;

        return this.httpClient.get<boolean>(url, options).toPromise();
    }

    resetPassword(userId: string, token: string, password: string) {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('ResetPassword.Candidate') + '?userId=' + userId + '&token=' + token + '&password=' + password;

        return this.httpClient.get<boolean>(url, options).toPromise();
    }

    requestPasswordReset(email: string): Promise<boolean> {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('RequestPasswordReset.Candidate') + '?email=' + email;

        return this.httpClient.get<boolean>(url, options).toPromise();
    }

    verifyToken(email: string, token: string, purpose: string, userId?: string): Observable<boolean> {
        const dto = {
            email,
            token,
            purpose,
            userId
        };

        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        const url = AppConfig.getUrl('Account.VerifyToken');

        return this.httpClient.post<boolean>(url, dto, options);
    }
}
