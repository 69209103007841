import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let displaySpinnerOnInterval: any;

        (() => displaySpinnerOnInterval = setTimeout(() => this.spinner.show(), 1000))();

        return next.handle(req).pipe(
            finalize(() => {
                // stop the settimeout from running (in case it hasn't started yet and the request already finished)
                clearTimeout(displaySpinnerOnInterval);
                // hide spinner if already running
                this.spinner.hide();
            })
        );
    }
}