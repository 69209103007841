import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class DiscardChangesGuard  {

    public canDeactivate(component: CanComponentDeactivate): boolean | Observable<boolean> {
        if (component.canDeactivate()) {
            return true;
        } else {
            return confirm('There are unsaved changes on this page!\nDo you want to DISCARD the changes?');
        }
    }
}
