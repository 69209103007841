import { Component, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Candidate } from '../../models/candidate.model';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConfig } from '../../app.config';
import { Constants } from '../../datatypes/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericResponse } from 'src/app/models/request-response.model';


@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

    public userNrOfCVS = 2;

    fileList: File[] = [];
    supportedFileTypes: string[] = Constants.DocumentsFormatAllowed;
    supportedFileTypesStr: string = this.supportedFileTypes.map(ext => '.' + ext).join();
    maxSize: number = Constants.MaxSizeDocumentAllowed;
    fileUploadUrl: string;
    uploading = false;

    constructor(
        public dialogRef: MatDialogRef<FileUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IFileUploadData,
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService
    ) {
        this.fileUploadUrl = AppConfig.getUrl('Document.Upload');
    }

    handleFiles(event: any): void {

        const fileExtRegExp: RegExp = /(?:\.([^.]+))?$/;

        this.fileList = new Array<File>();
        for (const file of event.target.files) {
            // Validating the extensions, more file type validation done on the server
            const currentFileExt = fileExtRegExp.exec(file.name)[1].toLowerCase();
            let isFormatValid = this.supportedFileTypes.includes(currentFileExt);

            if (isFormatValid && (file.size <= this.maxSize)) {
                this.fileList.push(file);
            } else {
                if (!isFormatValid) {
                    this.toastr.error('Oops! File type must be pdf, doc, docx', 'File rejected');
                }
                if (file.size > this.maxSize) {
                    this.toastr.error('Maximum file size is 10MB', 'File rejected');
                }
            }
        }

        if (this.fileList.length + this.userNrOfCVS > 3) {
            this.toastr.error('Only 3 CVs are allowed', 'Upload Blocked');
        }
    }

    checkUpload(): boolean {
        if (this.fileList.length < 1) {
            return false;
        }

        if (this.fileList.length + this.userNrOfCVS > 3) {
            return false;
        }
        return true;
    }

    // TODO - FIXME - needs refactoring to use common libraries
    doUpload() {
        if (this.data.UploadToServer) {
            this.uploading = true;
            const data = new FormData();

            let hdr = new HttpHeaders();
            hdr = hdr.append('Authorization', `Bearer ${this.authenticationService.token}`);

            const options = { headers: hdr };

            // FIXME - these should be constants
            for (let i = 0; i < this.fileList.length; i++) {
                data.append('myFile-' + i, this.fileList[i]);
                data.append('candidate', JSON.stringify(this.data.Candidate)); // passing any data along with the file
            }

            
            this.httpClient.post<GenericResponse>(this.fileUploadUrl, data, options)
                .subscribe(
                    res => this.onUploadDone(res),
                    err => this.onUploadFailed(err));
        } else {
            this.dialogRef.close(this.fileList[0]);
        }
    }

    private formatBytes(a: number, b: number) {
        if (0 === a) {
            return '0 Bytes';
        }

        const c = 1024, d = b || 2, e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            f = Math.floor(Math.log(a) / Math.log(c));
        return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
    }

    // TODO  check if no CVs exist & set candidate.isCvAdded = false;
    private removeCV(file: File) {
        const i = this.fileList.indexOf(file);
        this.fileList.splice(i, 1);
    }

    private onUploadDone(res: GenericResponse) {
        this.uploading = false;
        this.dialogRef.close(true);
        
        if (res.Success)
        {
          // this.Candidate.HasCv = true;
          this.toastr.success('CV uploaded');
        }
        else 
        {
            if (res.MultipleFailure)
            {
                for (let errMsg of res.StatusMessages)
                {
                    this.toastr.error(errMsg, res.StatusTitle)
                }

            }
            else 
            {
                this.toastr.error(res.StatusMessage, res.StatusTitle)
            }
        }
    }

    private onUploadFailed(res: HttpErrorResponse) {
        this.uploading = false;
        
        if (res.error){
            this.toastr.error(res.error);
        }
        else if (res.statusText) {            
            this.toastr.error(res.statusText);
        } else {
            this.toastr.error('Failed to Upload CV');
        }
    }

   

}


export interface IFileUploadData {
    Title: string,
    Candidate: Candidate,
    // use if passing selected file to parent model or upload directly to server
    UploadToServer: boolean
}