import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Advert } from '../../models/advert.model';
import { AdvertQuery } from '../../models/advertQuery.model';
import { AdvertService } from '../../services/advert.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { AppConfig } from '../../app.config';
import { HelpersService } from '../../helpers/helpers.service';
import { SearchFormSharedService } from '../search/searchForm/searchForm.shared.service';
import { ILocationData } from '../../datatypes/misc';
import { CorporateConfig } from '../../corporate.config';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    model: AdvertQuery;

    AdvertsList: Advert[] = [];
    proximityDistanceList: number[] = [];
    isNoLocation = true;

    isLoggedIn: boolean;

    homeHeading: string;
    homeSubHeading: string;
    homeText: string;
    homeSecondaryLogoLink: string;
    homeSecondarySeeMoreLink: string;
    homeSecondaryTitle: string;
    homeSecondaryText: string;

    searchKeywordPlaceholder: string;

    readonly UseSearchAreaDistanceControl = AppConfig.UseSearchAreaDistanceControl;

    disableDistanceSelection = false;

    constructor(
        public advertService: AdvertService,
        public searchFormSharedService: SearchFormSharedService,
        public localStorageService: LocalStorageService,
        private router: Router,
        public helpersService: HelpersService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.homepageTitle));
        this.metaService.updateTag({name: 'description', content: this.helpersService.getPageContent(CorporateConfig.homepageDescription)} as MetaDefinition);
        this.model = new AdvertQuery();
    }

    ngOnInit(): void {

        this.advertService.getActiveAdvertList().then(result => this.setAdvertList(result));
        this.proximityDistanceList = this.helpersService.getProximityDistance();
        this.isLoggedIn = this.localStorageService.isUserStillActive();

        this.homeHeading = this.helpersService.getPageContent(CorporateConfig.homeHeading);
        this.homeSubHeading = this.helpersService.getPageContent(CorporateConfig.homeSubHeading);
        this.homeText = this.helpersService.getPageContent(CorporateConfig.homeText);

        this.homeSecondaryLogoLink = this.helpersService.getPageContent(CorporateConfig.HomeSecondaryLogoLink);
        this.homeSecondarySeeMoreLink = this.helpersService.getPageContent(CorporateConfig.HomeSecondarySeeMoreLink);
        this.homeSecondaryTitle = this.helpersService.getPageContent(CorporateConfig.HomeSecondaryTitle);
        this.homeSecondaryText = this.helpersService.getPageContent(CorporateConfig.HomeSecondaryText);

        this.searchKeywordPlaceholder = this.helpersService.getPageContent(CorporateConfig.searchKeywordPlaceholder);
      
    }

    setAdvertList(result: Advert[]) {
        this.AdvertsList = result;
    }

    submitSearch(inputs: any) {
        this.searchFormSharedService.setData(this.model);
        this.router.navigate(['/search']);
    }

    getAllJobs() {
        this.searchFormSharedService.getAllJobs();
    }

    setLocationData(result: ILocationData) {
        this.isNoLocation = (result.LocationName === '');
        if (this.UseSearchAreaDistanceControl) {
            const conurbation = result.PlaceResultMetaData.IsLargeConurbation;
            if (conurbation) {
                this.disableDistanceSelection = conurbation;
                this.model.LocationPlaceData.DistanceInMiles = 0;
            } else {
                this.disableDistanceSelection = false;
            }
        }
    }


    isReverseLocationSearch(event: boolean) {
        if (event) {
            this.isNoLocation = false;
        }
    }

    searchOnEnterKey(event: any) {
        // carriage return
        if (event.keyCode === 13) {
            this.submitSearch(this.model);
        }
    }
}
