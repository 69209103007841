import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CorporateConfig {
  private static data: Content;

   private readonly BOOTSTRAP_CONFIG_URL = '/assets/base/Content.json';

  //// For testing tenant locally
  //private readonly BOOTSTRAP_CONFIG_URL = '/assets/tenant-resources/uniperms/Content.json';
  // private readonly BOOTSTRAP_CONFIG_URL = '/assets/tenant-resources/search-higher/Content.json';
  // private readonly BOOTSTRAP_CONFIG_URL = '/assets/tenant-resources/wtr/Content.json';
  // private readonly BOOTSTRAP_CONFIG_URL = '/assets/tenant-resources/lion/Content.json';

  // The next url for using the default content instead of the corporate one
   //private readonly BOOTSTRAP_CONFIG_URL = "/assets/defaultContent.json";

  constructor(private httpClient: HttpClient) {}

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load(): Promise<any> {
    return this.httpClient
      .get(this.BOOTSTRAP_CONFIG_URL)
      .toPromise()
      .then((data: any) => (CorporateConfig.data = data))
      .catch((err: any) => Promise.resolve());
  }

  public static get candidatePage(): string {
    return CorporateConfig.data.candidatePageContent;
  }

  public static get displayContactPageTitle(): string {
    return CorporateConfig.data.displayContactPageTitle;
  }

  public static get displayAboutPageTitle(): string {
    return CorporateConfig.data.displayAboutPageTitle;
  }

  public static get displayClientPageTitle(): string {
    return CorporateConfig.data.displayClientPageTitle;
  }

  public static get displayCandidatePageTitle(): string {
    return CorporateConfig.data.displayCandidatePageTitle;
  }

  public static get twitterLink(): string {
    return CorporateConfig.data.twitterLink;
  }

  public static get facebookLink(): string {
    return CorporateConfig.data.facebookLink;
  }

  public static get linkedInLink(): string {
    return CorporateConfig.data.linkedInLink;
  }

  public static get advicePage(): string {
    return CorporateConfig.data.advicePageContent;
  }

  public static get clientsPage(): string {
    return CorporateConfig.data.clientsPageContent;
  }

  public static get contactPage(): string {
    return CorporateConfig.data.contactPageContent;
  }

  public static get aboutPage(): string {
    return CorporateConfig.data.aboutPageContent;
  }

  public static get termsOfUsePage(): string {
    return CorporateConfig.data.termsOfUsePageContent;
  }

  public static get privacyPolicyPage(): string {
    return CorporateConfig.data.privacyPolicyPageContent;
  }

  public static get privacyNoticePage(): string {
    return CorporateConfig.data.privacyNoticePageContent;
  }

  public static get modernSlaveryActPage(): string {
    return CorporateConfig.data.modernSlaveryActPageContent;
  }

  public static get freedomOfInformationActPage(): string {
    return CorporateConfig.data.freedomOfInformationActPageContent;
  }

  public static get infoHubPage(): string {
    return CorporateConfig.data.infoHubPageContent;
  }

  public static get accessibilityStatementPageContent(): string {
    return CorporateConfig.data.accessibilityStatementPageContent;
  }

  public static get footerSubLogoText(): string {
    return CorporateConfig.data.footerSubLogoText;
  }

  public static get footerCopyRightText(): string {
    return CorporateConfig.data.footerCopyRightText;
  }

  public static get cookiePage(): string {
    return CorporateConfig.data.cookiePageContent;
  }

  public static get CandidateLink(): string {
    return CorporateConfig.data.CandidateLink;
  }

  public static get ClientsLink(): string {
    return CorporateConfig.data.ClientsLink;
  }

  public static get ContactUsLink(): string {
    return CorporateConfig.data.ContactUsLink;
  }

  public static get NewsAndEventsLink(): string {
    return CorporateConfig.data.NewsAndEventsLink;
  }

  public static get AboutUsLink(): string {
    return CorporateConfig.data.AboutUsLink;
  }

  public static get HomeSecondaryLogoLink(): string {
    return CorporateConfig.data.HomeSecondaryLogoLink;
  }

  public static get HomeSecondaryTitle(): string {
    return CorporateConfig.data.HomeSecondaryTitle;
  }

  public static get HomeSecondaryText(): string {
    return CorporateConfig.data.HomeSecondaryText;
  }

  public static get HomeSecondarySeeMoreLink(): string {
    return CorporateConfig.data.HomeSecondarySeeMoreLink;
  }

  public static get footerLinkTermsOfUse(): string {
    return CorporateConfig.data.footerLinkTermsOfUse;
  }

  public static get footerLinkPrivacyPolicy(): string {
    return CorporateConfig.data.footerLinkPrivacyPolicy;
  }

  public static get footerLinkCookiePolicy(): string {
    return CorporateConfig.data.footerLinkCookiePolicy;
  }

  public static get footerLinkAccessibility(): string {
    return CorporateConfig.data.footerLinkAccessibility;
  }

  public static get footerLinkModernSlaveryAct(): string {
    return CorporateConfig.data.footerLinkModernSlaveryAct;
  }

  public static get footerLinkFreedomOfInformationAct(): string {
    return CorporateConfig.data.footerLinkFreedomOfInformationAct;
  }

  public static get dashBoardOuterStrokeColor(): string {
    return CorporateConfig.data.dashBoardOuterStrokeColor;
  }

  public static get dashBoardInnerStrokeColor(): string {
    return CorporateConfig.data.dashBoardInnerStrokeColor;
  }

  public static get communicationPreferencesIntro(): string {
    return CorporateConfig.data.communicationPreferencesIntro;
  }

  public static get communicationPreferencesOuttro(): string {
    return CorporateConfig.data.communicationPreferencesOuttro;
  }

  public static get termsAndConditions(): string {
    return CorporateConfig.data.termsAndConditions;
  }

  public static get acceptanceofTermsandConditions(): string {
    return CorporateConfig.data.acceptanceofTermsandConditions;
  }

  public static get consentStatementPart1(): string {
    return CorporateConfig.data.consentStatementPart1;
  }

  public static get consentStatementPart2(): string {
    return CorporateConfig.data.consentStatementPart2;
  }

  public static get consentStatementPart2b(): string {
    return CorporateConfig.data.consentStatementPart2b;
  }

  public static get consentStatementPart3(): string {
    return CorporateConfig.data.consentStatementPart3;
  }

  public static get consentLinkPrivacyNotice(): string {
    return CorporateConfig.data.consentLinkPrivacyNotice;
  }

  public static get footerLogoText(): string {
    return CorporateConfig.data.footerLogoText;
  }

  public static get instagramLink(): string {
    return CorporateConfig.data.instagramLink;
  }

  public static get homepageTitle(): string {
    return CorporateConfig.data.homepageTitle;
  }

  public static get homepageDescription(): string {
    return CorporateConfig.data.homepageDescription;
  }

  public static get candidateTitle(): string {
    return CorporateConfig.data.candidateTitle;
  }

  public static get candidateDescription(): string {
    return CorporateConfig.data.candidateDescription;
  }

  public static get clientTitle(): string {
    return CorporateConfig.data.clientTitle;
  }

  public static get clientDescription(): string {
    return CorporateConfig.data.clientDescription;
  }

  public static get contactUsTitle(): string {
    return CorporateConfig.data.contactUsTitle;
  }

  public static get contactUsDescription(): string {
    return CorporateConfig.data.contactUsDescription;
  }

  public static get aboutUsTitle(): string {
    return CorporateConfig.data.aboutUsTitle;
  }

  public static get aboutUsDescription(): string {
    return CorporateConfig.data.aboutUsDescription;
  }

  public static get loginTitle(): string {
    return CorporateConfig.data.loginTitle;
  }

  public static get loginDescription(): string {
    return CorporateConfig.data.loginDescription;
  }

  public static get registrationTitle(): string {
    return CorporateConfig.data.registrationTitle;
  }

  public static get registrationDescription(): string {
    return CorporateConfig.data.registrationDescription;
  }

  public static get searchTitle(): string {
    return CorporateConfig.data.searchTitle;
  }

  public static get searchDescription(): string {
    return CorporateConfig.data.searchDescription;
  }

  public static get dashboardTitle(): string {
    return CorporateConfig.data.dashboardTitle;
  }

  public static get manageAccountTitle(): string {
    return CorporateConfig.data.manageAccountTitle;
  }

  public static get savedJobsTitle(): string {
    return CorporateConfig.data.savedJobsTitle;
  }

  public static get applicationsTitle(): string {
    return CorporateConfig.data.applicationsTitle;
  }

  public static get infoHubTitle(): string {
    return CorporateConfig.data.infoHubTitle;
  }

  public static get searchKeywordPlaceholder(): string {
    return CorporateConfig.data.searchKeywordPlaceholder;
  }

  public static get searchLocationPlaceholder(): string {
    return CorporateConfig.data.searchLocationPlaceholder;
  }

  public static get termsOfUseTitle(): string {
    return CorporateConfig.data.termsOfUseTitle;
  }

  public static get termsOfUseDescription(): string {
    return CorporateConfig.data.termsOfUseDescription;
  }

  public static get privacyPolicyTitle(): string {
    return CorporateConfig.data.privacyPolicyTitle;
  }

  public static get privacyPolicyDecription(): string {
    return CorporateConfig.data.privacyPolicyDecription;
  }

  public static get cookiePolicyTitle(): string {
    return CorporateConfig.data.cookiePolicyTitle;
  }

  public static get cookiePolicyDescription(): string {
    return CorporateConfig.data.cookiePolicyDescription;
  }

  public static get accessibilityStatementTitle(): string {
    return CorporateConfig.data.accessibilityStatementTitle;
  }

  public static get accessibilityStatementDescription(): string {
    return CorporateConfig.data.accessibilityStatementDescription;
  }

  public static get privacyNoticeTitle(): string {
    return CorporateConfig.data.privacyNoticeTitle;
  }

  public static get privacyNoticeDescription(): string {
    return CorporateConfig.data.privacyNoticeDescription;
  }

  public static get advertTitle(): string {
    return CorporateConfig.data.advertTitle;
  }

  public static get drivingLicenseInfo(): string {
    return CorporateConfig.data.drivingLicenseInfo;
  }

  public static get displayOurServicePageTitle(): string {
    return CorporateConfig.data.displayOurServicePageTitle;
  }

  public static get footerCompanyDetails(): string {
    return CorporateConfig.data.footerCompanyDetails;
  }

  public static get homeHeading(): string {
    return CorporateConfig.data.homeHeading;
  }

  public static get homeSubHeading(): string {
    return CorporateConfig.data.homeSubHeading;
  }

  public static get homeText(): string {
    return CorporateConfig.data.homeText;
  }

  public static get criminalConvictionsSubParagraph(): string {
    return CorporateConfig.data.criminalConvictionsSubParagraph;
  }
}

export class Content {
  constructor(
    public twitterLink: string,
    public facebookLink: string,
    public linkedInLink: string,
    public instagramLink: string,
    public displayContactPageTitle: string,
    public displayAboutPageTitle: string,
    public displayClientPageTitle: string,
    public displayCandidatePageTitle: string,
    public candidatePageContent: string,
    public advicePageContent: string,
    public clientsPageContent: string,
    public contactPageContent: string,
    public aboutPageContent: string,
    public termsOfUsePageContent: string,
    public privacyPolicyPageContent: string,
    public privacyNoticePageContent: string,
    public modernSlaveryActPageContent: string,
    public freedomOfInformationActPageContent: string,
    public infoHubPageContent: string,
    public accessibilityStatementPageContent: string,
    public footerSubLogoText: string,
    public footerCopyRightText: string,
    public cookiePageContent: string,
    public CandidateLink: string,
    public ClientsLink: string,
    public ContactUsLink: string,
    public NewsAndEventsLink: string,
    public AboutUsLink: string,
    public homeHeading: string,
    public homeSubHeading: string,
    public homeText: string,
    public HomeSecondaryLogoLink: string,
    public HomeSecondaryTitle: string,
    public HomeSecondaryText: string,
    public HomeSecondarySeeMoreLink: string,
    public footerLinkTermsOfUse: string,
    public footerLinkPrivacyPolicy: string,
    public footerLinkCookiePolicy: string,
    public footerLinkAccessibility: string,
    public footerLinkModernSlaveryAct: string,
    public footerLinkFreedomOfInformationAct: string,
    public dashBoardOuterStrokeColor: string,
    public dashBoardInnerStrokeColor: string,
    public communicationPreferencesIntro: string,
    public communicationPreferencesOuttro: string,
    public termsAndConditions: string,
    public acceptanceofTermsandConditions: string,
    public consentStatementPart1: string,
    public consentStatementPart2: string,
    public consentStatementPart2b: string,
    public consentStatementPart3: string,
    public consentLinkPrivacyNotice: string,
    public footerLogoText: string,
    public homepageTitle: string,
    public homepageDescription: string,
    public candidateTitle: string,
    public candidateDescription: string,
    public clientTitle: string,
    public clientDescription: string,
    public contactUsTitle: string,
    public contactUsDescription: string,
    public aboutUsTitle: string,
    public aboutUsDescription: string,
    public loginTitle: string,
    public loginDescription: string,
    public registrationTitle: string,
    public registrationDescription: string,
    public searchTitle: string,
    public searchDescription: string,
    public dashboardTitle: string,
    public manageAccountTitle: string,
    public savedJobsTitle: string,
    public applicationsTitle: string,
    public infoHubTitle: string,
    public searchKeywordPlaceholder: string,
    public searchLocationPlaceholder: string,
    public termsOfUseTitle: string,
    public termsOfUseDescription: string,
    public privacyPolicyTitle: string,
    public privacyPolicyDecription: string,
    public cookiePolicyTitle: string,
    public cookiePolicyDescription: string,
    public accessibilityStatementTitle: string,
    public accessibilityStatementDescription: string,
    public privacyNoticeTitle: string,
    public privacyNoticeDescription: string,
    public advertTitle: string,
    public drivingLicenseInfo: string,
    public displayOurServicePageTitle: string,
    public footerCompanyDetails: string,
    public criminalConvictionsSubParagraph: string
  ) {}
}
