import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './app.config';
import { CorporateConfig } from './corporate.config';
import { AppComponent } from './components/app/app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { ClientsComponent } from './components/clients/clients.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { InfohubComponent } from './components/infohub/infohub.component';
import { CandidatesComponent } from './components/candidates/candidates.component';
import { AdviceComponent } from './components/advice/advice.component';
import { PrivacyPolicyComponent } from './components/policy/privacy-policy/privacy-policy.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { TermsOfUseComponent } from './components/policy/terms-of-use/terms-of-use.component';
import { AcceptedTermsAndConditionsComponent } from './components/accepted-terms-and-conditions/accepted-terms-and-conditions.component';
import { ModernSlaveryActComponent } from './components/policy/modern-slavery-act/modern-slavery-act.component';
import { FreedomOfInformationActComponent } from './components/policy/freedom-of-information-act/freedom-of-information-act.component';
import { SharedModule } from './modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationsComponent } from './components/applications/applications.component';
import { OptionalFieldService } from './services/optional-field-service';
import { AccountService } from './services/account.service';
import { AdvertService } from './services/advert.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PreviousRouteService } from './services/previous-route.service';
import { JbeCustomModalService } from './components/search/jbe-custom-modal/services/custom-jbe-modal.service';
import { UnsubscribeSuccessfulComponent } from './components/unsubscribe-successful/unsubscribe-successful.component';
import { SkeletonUnsubscribeService } from './components/unsubscribe-successful/services/skeleton-unsubscribe.service';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { EmailConsentComponent } from './components/email-consent/email-consent.component';
import { TenantService } from './services/tenant.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobLatestComponent } from './components/job-latest/job-latest.component';
import { SpinnerInterceptor } from './components/common/spinner.interceptor';
import { JobService } from './services/job.service';

import { TruncatePipe } from './pipes/truncate/truncate-pipe';
import { CookieComponent } from './components/policy/cookies/cookies.component';
import { AccessibilityStatementComponent } from './components/policy/accessibility-statement/accessibility-statement.component';



export function startupServiceFactory(appConfig: AppConfig): Function {
    return () => appConfig.load();
}

export function corporateStartupServiceFactory(corporateConfig: CorporateConfig): Function {
    return () => corporateConfig.load();
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    NavMenuComponent,
    ClientsComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    HomeComponent,
    InfohubComponent,
    CandidatesComponent,
    AdviceComponent,
    PrivacyPolicyComponent,
    PrivacyNoticeComponent,
    TermsOfUseComponent,
    AcceptedTermsAndConditionsComponent,
    ModernSlaveryActComponent,
    FreedomOfInformationActComponent,
    ApplicationsComponent,
    UnsubscribeSuccessfulComponent,
    ConfirmPasswordComponent,
    EmailConsentComponent,
    JobLatestComponent,
    TruncatePipe,
    CookieComponent,
    AccessibilityStatementComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    // This is the profile progress circle
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#d90429',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    AppRoutingModule,
    SharedModule.forRoot(),
  ],
  providers: [
    AppConfig,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AppConfig],
      multi: true,
    },
    CorporateConfig,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: corporateStartupServiceFactory,
      deps: [CorporateConfig],
      multi: true,
    },
    AccountService,
    AdvertService,
    OptionalFieldService,
    PreviousRouteService,
    JbeCustomModalService,
    SkeletonUnsubscribeService,
    TenantService,
    JobService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
