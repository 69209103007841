import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { HttpHelperInitiliseParams } from '../datatypes/misc';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { Cv } from '../models/document.model';
import { CandidateSavedJob } from '../models/candidateSavedJob.model';
import { JobAlertAccountDto } from '../components/search/jbe-custom-modal/models';
import { ConsentDetails } from '../models/candidate.model';

@Injectable()
export class CandidateService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService,
        private router: Router, ) {
        super(httpClientHelper, authenticationService, helper);
    }

    getCvs(candidateId: number): Promise<Cv> {
        return this.httpGetWithParams<Cv>('Candidate.CvList', [`?candidateId=${candidateId}`]);
    }

    saveJob(advertIdOrGuid: string): Observable<boolean> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Candidate.SaveJob', advertIdOrGuid);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(
            map(this.helper.extractData),
            catchError(this.helper.handleErrorObservable));
    }

    removeSavedJob(candidateSavedJobId: number): Promise<boolean> {
        return this.httpDeleteWithParams<boolean>('Candidate.RemoveSavedJob', [`?candidateSavedJobId=${candidateSavedJobId}`]);
    }

    listCandidateSavedJobs(): Promise<CandidateSavedJob[]> {
        return this.httpGetWithoutParams<CandidateSavedJob[]>('Candidate.ListSavedJobs');
    }

    saveJobFromLocalStorage(): void {
        let savedJob: { path: string, jobId: string };
        try {
            savedJob = JSON.parse(sessionStorage.getItem('SavedJob') || '{}');
            if (Object.keys(savedJob).length !== 0) {
                this.saveJob(savedJob.jobId).subscribe(
                    res => {
                        if (res === true) {
                            sessionStorage.removeItem('SavedJob');
                            this.router.navigate([savedJob.path.replace('%20', ' ')]);
                        } else {
                            console.log('Job failed to be saved');
                            sessionStorage.removeItem('SavedJob');
                            this.router.navigate(['/dashboard']);
                            window.scrollTo(0, 0);
                        }
                    },
                    err => {
                        console.log(err);
                        sessionStorage.removeItem('SavedJob');
                        this.router.navigate(['/dashboard']);
                        window.scrollTo(0, 0);
                    });
            }
        } catch (e) {
            console.log('Saved Job error: ' + e);
            sessionStorage.removeItem('SavedJob');
            this.router.navigate(['/dashboard']);
            window.scrollTo(0, 0);
        }
    }

    isSavedJobInLocalStorage(): boolean {
        let savedJob: { path: string, jobId: number };
        try {
            savedJob = JSON.parse(sessionStorage.getItem('SavedJob') || '{}');
            if (Object.keys(savedJob).length !== 0) {
                return true;
            }
        } catch (e) {
            console.log('Saved Job Local storage error: ' + e);
            sessionStorage.removeItem('SavedJob');
        }
        return false;
    }

    isEmailAlreadyExisting(email: string): Promise<boolean> {
        return this.httpGetWithParams<boolean>('Candidate.EmailAlreadyExists', [`?email=${email}`]);
    }

    fetchOutsideUkEeaConsentDetails(): Promise<ConsentDetails> {
        return this.httpGetWithoutParams<ConsentDetails>('Candidate.OutsideUkEeaConsentDetails');
    }
    
    doesJbeAccountExist(email: string): Observable<JobAlertAccountDto> {
        return this.httpGetWithParamsObs('Candidate.JbeAccountExists', [`?email=${email}`]);
    }

    confirmEmailConsent(token: string): Observable<boolean> {
        return this.httpGetWithParamsObs('Candidate.EmailConsent', [`?token=${token}`]);
    }

    confirmEmailAcceptanceOfTsCs(Guid: string): Observable<boolean> {
        return this.httpGetWithParamsObs('Candidate.EmailAcceptanceOfTsCs', [`?Guid=${Guid}`]);
    }

    isConsentForAllJobs(email: string): Promise<boolean> {
        return this.httpGetWithParams<boolean>('Candidate.ConsentForAllJobs', [`?email=${email}`]);
    }

    isAcceptanceOfTermsAndConditionsSetsConsentForAllJobs(): Promise<boolean> {
        return this.httpGetWithoutParams<boolean>('Candidate.AcceptanceOfTermsAndConditionsSetsConsentForAllJobs');
    }
}
