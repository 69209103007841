import { ElementRef, Component, NgZone, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { PlaceResultMetaData, ILocationData } from '../../../datatypes/misc';
import { Constants } from '../../../datatypes/constants';
import { PlaceResultHelper } from '../../../helpers/PlaceResultHelper';

import { CorporateConfig } from '../../../corporate.config';
import { HelpersService } from '../../../helpers/helpers.service';

declare const google: any;

@Component({
  selector: 'location-search',
  templateUrl: './locationsearch.component.html',
  styleUrls: ['./locationsearch.component.css'],
})
export class LocationSearchComponent implements AfterViewInit {
  @Input()
  public locationPlaceData: ILocationData;

  @Output()
  locationData = new EventEmitter<ILocationData>();

  @Output()
  isReverseLocationSearch = new EventEmitter<boolean>();

  @ViewChild('search', { static: false })
  searchElementRef: ElementRef;

  placeResultMetaData: PlaceResultMetaData;
  latitude: number;
  longitude: number;
  currentLocation: string;
  locSelected = false;
  searchLocationPlaceholder: string;

  private Autocomplete: google.maps.places.Autocomplete;
  private place: google.maps.places.PlaceResult;
  private placeResultHelper: PlaceResultHelper;

  constructor(private ngZone: NgZone, public helpersService: HelpersService) {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.latitude = +pos.coords.latitude;
        this.longitude = +pos.coords.longitude;
      });
    }

    this.placeResultHelper = new PlaceResultHelper();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    // load Places Autocomplete
    this.Autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {}
    );

    this.Autocomplete.addListener('place_changed', () => {
      this.locSelected = true;
      this.ngZone.run(() => {
        this.handleAutocompleteLocationSearch();
      });
    });

    this.searchLocationPlaceholder = this.helpersService.getPageContent(
      CorporateConfig.searchLocationPlaceholder
    );
  }

  GetCurrentLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.latitude = +pos.coords.latitude;
        this.longitude = +pos.coords.longitude;
        this.doReverseAddressLookup();
      });
    }
  }

  selectLocation() {
    this.locSelected = false;
  }

  hasLocationSelected() {
    if (
      (!this.locSelected &&
        this.place &&
        this.locationPlaceData.LocationName.trim() !==
          this.place.formatted_address) ||
      (!this.locSelected && !this.place)
    ) {
      this.locationPlaceData.LocationName = '';
      this.emitLocationData();
    }
  }

  doReverseAddressLookup() {
    const geo = new google.maps.Geocoder();
    geo.geocode(
      { location: { lat: this.latitude, lng: this.longitude } },
      (results, status) => {
        this.handleGeoResults(results, status);
      }
    );
    this.onReverseLocationSearch(true);
  }

  onReverseLocationSearch(event: boolean) {
    this.isReverseLocationSearch.emit(event);
  }

  private handleAutocompleteLocationSearch() {
    this.place = this.Autocomplete.getPlace();

    if (this.place.geometry === undefined || this.place.geometry === null) {
      this.locationPlaceData = {} as ILocationData;
      return;
    }

    const addressComponents = this.place.address_components;
    const neGeometry = this.place.geometry.viewport.getNorthEast();
    const swGeometry = this.place.geometry.viewport.getSouthWest();

    this.placeResultMetaData = this.placeResultHelper.analysePlaceResultV2(
      addressComponents,
      neGeometry,
      swGeometry
    );
    this.locationPlaceData.PlaceResultMetaData = this.placeResultMetaData;
    this.currentLocation = this.place.formatted_address;
    this.setLatLong(
      this.place.geometry.location.lat(),
      this.place.geometry.location.lng()
    );
    this.setLocationName(this.searchElementRef.nativeElement.value);
    this.emitLocationData();
  }

  private emitLocationData() {
    this.locationData.emit(this.locationPlaceData);
  }

  private setLatLong(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
    this.locationPlaceData.Coordinates = { Lat: lat, Lng: lng };
  }

  private setLocationName(locationName: string) {
    this.locationPlaceData.LocationName = locationName;
  }

  // for address_components get the element where type contains postal_code_prefix
  private handleGeoResults(
    results: google.maps.GeocoderResult[],
    status: google.maps.GeocoderStatus
  ) {
    if (status === google.maps.GeocoderStatus.OK) {
      for (const geoResult of results) {
        if (
          geoResult.types.some(
            (t) =>
              t === Constants.GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_CODE_PREFIX
          )
        ) {
          const formattedAddr = geoResult.formatted_address;

          const neGeometry = geoResult.geometry.bounds.getNorthEast();
          const swGeometry = geoResult.geometry.bounds.getSouthWest();

          this.placeResultMetaData =
            this.placeResultHelper.analysePlaceResultV2(
              geoResult.address_components,
              neGeometry,
              swGeometry
            );
          this.locationPlaceData.PlaceResultMetaData = this.placeResultMetaData;

          this.setLatLong(
            geoResult.geometry.location.lat(),
            geoResult.geometry.location.lng()
          );

          this.searchElementRef.nativeElement.value = formattedAddr;
          this.setLocationName(this.searchElementRef.nativeElement.value);
          this.emitLocationData();
          return;
        }
      }
    }
  }
}
