import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import * as $ from 'jquery';

import { LocalStorageService } from '../../services/local-storage.service';
import { AccountService } from '../../services/account.service';
import { Candidate } from '../../models/candidate.model';
import { AuthenticationService } from '../../services/authentication.service';
import { SearchFormSharedService } from '../search/searchForm/searchForm.shared.service';
import { HelpersService } from '../../helpers/helpers.service';
import { CorporateConfig } from '../../corporate.config';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
    selector: 'nav-menu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.css']
})
export class NavMenuComponent implements OnInit {

    public user: Candidate;
    public isLoggedIn = false;

    public CandidateLink: string;
    public ClientsLink: string;
    public ContactUsLink: string;
    public NewsAndEventsLink: string;
    public AboutUsLink: string;
    tenantName: string;
    @ViewChild('menuToggler') menuToggler: ElementRef;
    @ViewChild('mobileLinks') mobileLinks: ElementRef;

    constructor(private helpersService: HelpersService,
        private localStorageService: LocalStorageService,
        private accountService: AccountService,
        private authenticationService: AuthenticationService,
        private router: Router,
        public searchFormSharedService: SearchFormSharedService,
        private tenantService: TenantService) {
    }

    ngOnInit() {
        this.tenantService.getTenantDetails()
            .subscribe(res => {
                this.tenantName = res.TenantDisplayName;
            })

        this.CandidateLink = this.helpersService.getPageContent(CorporateConfig.CandidateLink);
        this.ClientsLink = this.helpersService.getPageContent(CorporateConfig.ClientsLink);
        this.ContactUsLink = this.helpersService.getPageContent(CorporateConfig.ContactUsLink);
        this.NewsAndEventsLink = this.helpersService.getPageContent(CorporateConfig.NewsAndEventsLink);
        this.AboutUsLink = this.helpersService.getPageContent(CorporateConfig.AboutUsLink);

        this.localStorageService.isLoggedInObs().subscribe(x => {
            if (x) {
                this.isLoggedIn = true;
                return this.GetCandidate();
            } else {
                this.isLoggedIn = false;
            }
        });
    }

    logOut() {
        this.authenticationService.logout();
        this.router.navigate(['/account/login']);
    }

    toggleCollapse() {
        let classes = this.mobileLinks.nativeElement.classList as string[];
        if (classes.length > 2) {
            this.menuToggler.nativeElement.click();
        }
        
    }

    getAllJobs() {
        this.searchFormSharedService.getAllJobs();
    }

    private GetCandidate() {
        return this.accountService.getCandidate().then(res => this.getUser(res)).catch(() => {
            return of(false);
        });
    }

    private getUser(input: Candidate) {
        this.user = input;
    }
}
