import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[number-input-directive]'
})
export class NumberInputDirective {

    // When using this directive ensure the host input type is text

    // Use only one input at a time for each field
    @Input() optionalDecimal: boolean = false;
    @Input() percentage: boolean = false;

    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'ControlLeft'];

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this.getRegularExpression())) {
            event.preventDefault();
        }
    }

    // remove the decimal point if no fractions
    @HostListener('blur')
    onBlur() {
        let value: string = this.el.nativeElement.value
        if (value.includes('.')) {
            let wholeNumber = value.split('.')[0];
            let fractionalPart = value.split('.')[1];
            if (fractionalPart === '') {
                this.el.nativeElement.value = wholeNumber;
            }
        }
    }


    private getRegularExpression(): RegExp {
        let regex: RegExp;
        switch (true) {
            case this.optionalDecimal:
                regex = new RegExp(/^\d+\.?\d{0,2}$/g);
                break;
            case this.percentage:
                regex = new RegExp(/^((100)|(\d{0,2}(\.\d{0,2})?))$/g);
                break;
            default:
                regex = new RegExp(/^\d+$/g)
        }
        return regex;
    }
}