import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Advert } from '../../models/advert.model';
import { AdvertQuery } from '../../models/advertQuery.model';
import { AdvertService } from '../../services/advert.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { AppConfig } from '../../app.config';
import { HelpersService } from '../../helpers/helpers.service';
import { SearchFormSharedService } from '../search/searchForm/searchForm.shared.service';
import { ILocationData } from '../../datatypes/misc';
import { CorporateConfig } from '../../corporate.config';

@Component({
    selector: 'job-latest',
    templateUrl: './job-latest.component.html',
    styleUrls: ['./job-latest.component.css']
})
export class JobLatestComponent implements OnInit {

    searchKeywordPlaceholder: string;

    public model: AdvertQuery;

    public AdvertsList: Advert[] = [];
    public proximityDistanceList: number[] = [];
    public isNoLocation = true;

    public isLoggedIn: boolean;
    
    public readonly UseSearchAreaDistanceControl = AppConfig.UseSearchAreaDistanceControl;

    public disableDistanceSelection = false;

    constructor(
        public advertService: AdvertService,
        public searchFormSharedService: SearchFormSharedService,
        public localStorageService: LocalStorageService,
        private router: Router,
        public helpersService: HelpersService,
    ) {
        this.model = new AdvertQuery();
    }

    ngOnInit(): void {

        this.advertService.getActiveAdvertList().then(result => this.setAdvertList(result));
        this.proximityDistanceList = this.helpersService.getProximityDistance();
        this.isLoggedIn = this.localStorageService.isUserStillActive();

        this.searchKeywordPlaceholder = this.helpersService.getPageContent(CorporateConfig.searchKeywordPlaceholder);
    }

    setAdvertList(result: Advert[]) {
        this.AdvertsList = result;
    }

    submitSearch(inputs: any) {
        this.searchFormSharedService.setData(this.model);
        this.router.navigate(['/search']);
    }

    getAllJobs() {
        this.searchFormSharedService.getAllJobs();
    }

    setLocationData(result: ILocationData) {
        this.isNoLocation = (result.LocationName === '');
        if (this.UseSearchAreaDistanceControl) {
            const conurbation = result.PlaceResultMetaData.IsLargeConurbation;
            if (conurbation) {
                this.disableDistanceSelection = conurbation;
                this.model.LocationPlaceData.DistanceInMiles = 0;
            } else {
                this.disableDistanceSelection = false;
            }
        }
    }


    isReverseLocationSearch(event: boolean) {
        if (event) {
            this.isNoLocation = false;
        }
    }

    searchOnEnterKey(event: any) {
        // carriage return
        if (event.keyCode === 13) {
            this.submitSearch(this.model);
        }
    }
}
