import { Component, Input, ViewChildren, QueryList, ViewChild, ElementRef, ChangeDetectorRef, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';

import { Candidate } from '../../../../models/candidate.model';
import { HelpersService } from '../../../../helpers/helpers.service';
import { ValidationConstants } from '../../../../datatypes/validation.constants';
import { FormSettingsHelperAbstractComponent } from '../form-settings-helper.abstract.component';
import { FormSettings } from '../../../../models/optionalFieldSettings.model';
import { CommonFields } from '../../../../datatypes/fields/common-fields.constants';
import { Dictionary } from '../../../../helpers/Dictionary';

@Component({
    selector: 'address',
    templateUrl: './address.component.html'
})

export class AddressComponent extends FormSettingsHelperAbstractComponent {

    @ViewChildren(NgModel) controls: QueryList<NgModel>;

    @Input()
    public model: Candidate;

    @Input()
    public formSettings: FormSettings;

    @Input()
    public submited: boolean;

    @Output()
    public onComponentChange = new EventEmitter<boolean>();

    public isFirstLineOk: boolean;
    public isSecondLineOk: boolean;
    public isCityOk: boolean;
    public isPostcodeOk: boolean;
    public isCountyOk: boolean;
    public isCountryOk: boolean;

    @ViewChild('postcode', { static: false }) postcode: ElementRef;

    public CountriesList: string[] = [];
    public CountyList: string[] = [];

    public postcodeValidation = ValidationConstants.ukPostcodeRegexValue;
    public requiredPostCode = false;

    constructor(
        protected parentForm: NgForm,
        protected helpersService: HelpersService,
        protected cdRef: ChangeDetectorRef
    ) {
        super(); 
    }

    ngOnInit(): void {
        this.CountriesList = this.helpersService.getCountries();
        this.CountyList = this.helpersService.getCountys();

        this.isStreet1Valid();
        this.isStreet2Valid();
        this.isCityValid();
        this.isPostCodeValid();
        this.isCountyValid();
        this.isCountryValid();
    }

    ngAfterViewInit() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.addControl(control);
        });
    }

    uppercase(value: string) {
        return value.toUpperCase();
    }

    isPostcodeRequired() {
        return this.model.Address.Country === 'United Kingdom' || this.isRequired(CommonFields.postcode);
    }

    isStreet1Valid(): boolean {
        this.isFirstLineOk = !!this.model.Address.Street1 || !this.isRequired(CommonFields.firstLineAddress);
        this.isComponentValid();
        return this.isFirstLineOk;
    }

    isStreet2Valid(): boolean {
        this.isSecondLineOk = !!this.model.Address.Street2 || !this.isRequired(CommonFields.secondLineAddress);
        this.isComponentValid();
        return this.isSecondLineOk;
    }

    isCityValid(): boolean {
        this.isCityOk = !!this.model.Address.City || !this.isRequired(CommonFields.city);
        this.isComponentValid();
        return this.isCityOk;
    }

    isPostCodeValid(): boolean {
        let regex = new RegExp(this.postcodeValidation);
        let isRegexOk = regex.test(this.model.Address.Postcode) || this.model.Address.Country !== 'United Kingdom';
        this.isPostcodeOk = (!this.model.Address.Postcode && !this.isPostcodeRequired()) || (!!this.model.Address.Postcode && isRegexOk);
        this.isComponentValid();
        return this.isPostcodeOk;
    }

    isCountyValid(): boolean {
        this.isCountyOk = !!this.model.Address.County || !this.isRequired(CommonFields.county) || this.model.Address.Country != 'United Kingdom';
        this.isComponentValid();
        return this.isCountyOk;
    }

    isCountryValid(): boolean {
        this.isCountryOk = !!this.model.Address.Country || !this.isRequired(CommonFields.country);
        this.isComponentValid();
        return this.isCountryOk;
    }

    isComponentValid() {
        let validators = new Dictionary([
            { key: 'First Line Address', value: this.isFirstLineOk },
            { key: 'Second Line Address', value: this.isSecondLineOk },
            { key: 'City', value: this.isCityOk },
            { key: 'Postcode', value: this.isPostcodeOk },
            { key: 'County', value: this.isCountyOk },
            { key: 'Country', value: this.isCountryOk }
        ]);

        for (let key in validators) {
            if (!validators[key]) {
                this.onComponentChange.emit(false);
                return;
            }
        }
        this.onComponentChange.emit(true);
    }
}
