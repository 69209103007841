import { Address } from './address.model';
import { AdvertQuery } from './advertQuery.model';
import { EAdvertSortOrder } from '../datatypes/misc';

export class Advert {

    public Address: Address;
    public Guid: string;
    public Logo: File;
    public ClientApplicationFormName: string;
    public ClientApplicationFormGuid: string;
    public JobDescriptionName: string;
    public JobDescriptionGuid: string;

    // don't understand why these have to be constructor parameters - they're not user defined/provided !!
    constructor(
        public AdvertId: string,
        public JobId: string,
        public JobTitle: string,
        public AdvertText: string,
        public AutoCompleteAddress: string,
        public SalaryFrom: number,
        public SalaryTo: number,
        public SalaryComment: string,
        public AdvertReferenceNumber: string,
        public PublishDate: string,
        public ClosingDate: string,
        public JobCategories: JobCategories[],
        public ContractTypeName: string,
        public HoursName: string, // obsolete property
        public ApplicationTypeName: string,
        public AdvertStatusName: string,
        public DraftStatus: boolean,
        public RemovedBy: string,
        public RemovedDate: string,
        public SysUser: string,
        public ContactInformation: string
    ) {
        this.Address = new Address('', '', '', '', '', '');
    }

    static createDefault(jobCategories: JobCategories[]): Advert {
        return new Advert('', '', '', '', '', 0, 0, '', '', '', '', jobCategories, '', '', '', '', true, '', '' , '', '');
    }
}

export class AdvertQueryResponse {
    public AdvertSortOrder: EAdvertSortOrder;
    public SearchQuery: AdvertQuery; // original query is sent back for pagination purposes
    public Results: AdvertSearchResult[];
    public TotalHits: number;
    public QueryTime: number;
    public Status: number;
    public NextCursorMark: string; // used for solr pagination
    public NoMorePages: boolean;
}

export class AdvertSearchResult {
    public HeroAdvertId: string;
    public Guid: string;
    public JobTitle: string;
    public JobTitleSnippet: string;
    public AdvertText: string;
    public AdvertTextSnippets: string[];
    public EmployerName: string;
    public JobCategoyFacet: string[];
    public HoursFacet: string;
    public ContractTypeFacet: string;
    public PublishDate: string;
    public ClosingDate: string;
    public SalaryFrom: number;
    public SalaryTo: number;
    public PostCode: string;
    public Locality: string[];
    public PostalTown: string[];
    public Score: number;
    public GeoDistance: number;
    public SalaryComment: string;
}

export class JobCategories {
    constructor(
        public AdvertJobCategoryId: number,
        public AdvertId: number,
        public JobCategoryId: number,
        public Name: string,
        public SysUser: string
    ) { }

    static createDefault(): JobCategories {
        return new JobCategories(0, 0, 0, '', '');
    }
}

export interface IJobCategory {
    JobCategoryId: number;
    Name: string;
}

export class AdvertEqualityQuestionsContainer {

    public readonly EqualityDataRecorded: boolean;

    // this collection will be empty if EqualityDataRecorded is false.
    public readonly Questions: EqualityQuestion[];

}

//   Wu.Weg.Hero.Core.DTOs.EqualityQuestionsDto
export class EqualityQuestion {
    readonly EqualityQuestionsId: number;
    readonly ConstantGroupId: number;
    readonly Question: string;
    readonly AdditionalText:string;
    readonly AnswerOptions: EqualityGroup;
}

//  Wu.Weg.Hero.Core.DTOs.ConstantGroupsDto
export interface EqualityGroup {
    readonly ConstantGroupsId: number;
    readonly Name: string;
    readonly Constants: EqualityData[];
}

export class EqualityGroupImpl implements EqualityGroup {
    ConstantGroupsId: number;
    Name: string;
    Constants: EqualityData[];
}

// equivalent to Wu.Weg.Hero.Core.DTOs.ConstantsDto.ConstantsDefault
export interface EqualityData {
    readonly ConstantsId: number;
    readonly ConstantGroupsId: number;
    readonly Value: number;
    readonly Name: string;
}
