/// <reference types="@angular/google-maps" />
// previous line has to be first line in this file for the import to work as from Angular 6+, the syntax is Typescript feature

import { Constants } from '../datatypes/constants';
import { PlaceResultMetaData, ILocationPlaceData } from '../datatypes/misc';

export class PlaceResultHelper {
  private static readonly radiansPerDegree = Math.PI / 180;
  private static readonly mileToKmConversionRatio = 1.60934;
  private static readonly diameterOfTheEarthInKm = 12742;

  static convertMilesToKm(distanceinMiles: number) {
    return distanceinMiles * PlaceResultHelper.mileToKmConversionRatio;
  }

  analysePlaceResultX(
    locationPlaceData: ILocationPlaceData
  ): ILocationPlaceData {
    locationPlaceData.PlaceResultMetaData = this.analysePlaceResult(
      locationPlaceData.PlaceResult
    );
    return locationPlaceData;
  }

  // obsolete - use analysePlaceResultV2
  analysePlaceResult(
    placeResult: google.maps.places.PlaceResult
  ): PlaceResultMetaData {
    const resultMetaData = new PlaceResultMetaData();
    resultMetaData.InitialPlaceType =
      placeResult.address_components[0].types[0];
    resultMetaData.BoundingBoxDiagonalDistanceInKm =
      this.calculateBoundingBoxDiagonal(placeResult.geometry);
    resultMetaData.PseudoGeoSpatialDistanceInKm =
      resultMetaData.BoundingBoxDiagonalDistanceInKm / 2;
    // console.log(`Bounding box distance:   ${resultMetaData.BoundingBoxDiagonalDistanceInKm} km`);
    const searchLocation = placeResult.address_components[0].long_name;
    // console.log(`Address Component [0].name  ${searchLocation}`);
    for (const address of placeResult.address_components) {
      for (const type of address.types) {
        if (address.long_name === searchLocation) {
          switch (type) {
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_TOWN:
              resultMetaData.IsPostalTown = true;
              // console.log(`POSTAL TOWN:  ${address.long_name}: type: ${type}`);
              break;
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_CODE:
              resultMetaData.IsPostalCode = true;
              // console.log(`POSTAL CODE:  ${address.long_name}: type: ${type}`);
              break;
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_2:
              resultMetaData.IsAdminAreaLevel2 = true;
              // console.log(`administrative_area_level_2:  ${address.long_name}: type: ${type}`);
              break;
            default:
          }
        }

        // if an address component includes the name of the search location, this sometimes indicates that
        // the search location is a large conurbation (Greater London, Los Angeles etc..)
        if (address.long_name.includes(searchLocation)) {
          if (
            type ===
              Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_1 ||
            type === Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_2
          ) {
            resultMetaData.IsLargeConurbation = true;
            // console.log(
            //    `Large Conurbation:  ${address.long_name}: type: ${type}`);
          }
        }
      } // endfor
    }
    return resultMetaData;
  }

  analysePlaceResultV2(
    address_components: google.maps.GeocoderAddressComponent[],
    neGeometry: google.maps.LatLng,
    swGeometry: google.maps.LatLng
  ): PlaceResultMetaData {
    const resultMetaData = new PlaceResultMetaData();
    resultMetaData.InitialPlaceType = address_components[0].types[0];
    resultMetaData.BoundingBoxDiagonalDistanceInKm =
      this.calculateBoundingBoxDiagonalV2(neGeometry, swGeometry);
    resultMetaData.PseudoGeoSpatialDistanceInKm =
      resultMetaData.BoundingBoxDiagonalDistanceInKm / 2;
    // console.log(`Bounding box distance:   ${resultMetaData.BoundingBoxDiagonalDistanceInKm} km`);
    const searchLocation = address_components[0].long_name;
    // console.log(`Address Component [0].name  ${searchLocation}`);
    for (const address of address_components) {
      for (const type of address.types) {
        if (address.long_name === searchLocation) {
          switch (type) {
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_TOWN:
              resultMetaData.IsPostalTown = true;
              // console.log(`POSTAL TOWN:  ${address.long_name}: type: ${type}`);
              break;
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_CODE:
              resultMetaData.IsPostalCode = true;
              // console.log(`POSTAL CODE:  ${address.long_name}: type: ${type}`);
              break;
            case Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_2:
              resultMetaData.IsAdminAreaLevel2 = true;
              // console.log(`administrative_area_level_2:  ${address.long_name}: type: ${type}`);
              break;
            default:
          }
        }

        // if an address component includes the name of the search location, this sometimes indicates that
        // the search location is a large conurbation (Greater London, Los Angeles etc..)
        if (address.long_name.includes(searchLocation)) {
          if (
            type ===
              Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_1 ||
            type === Constants.GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_2
          ) {
            resultMetaData.IsLargeConurbation = true;
            // console.log(
            //    `Large Conurbation:  ${address.long_name}: type: ${type}`);
          }
        }
      } // endfor

      resultMetaData.IsValidLocation =
        resultMetaData.BoundingBoxDiagonalDistanceInKm > 0 &&
        typeof resultMetaData.InitialPlaceType !== 'undefined' &&
        resultMetaData.InitialPlaceType != null &&
        resultMetaData.InitialPlaceType !== '';
    }
    return resultMetaData;
  }

  private calculateBoundingBoxDiagonalV2(
    neGeometry: google.maps.LatLng,
    swGeometry: google.maps.LatLng
  ): number {
    return this.calculateDistanceBetweenPoints(
      neGeometry.lat(),
      swGeometry.lat(),
      neGeometry.lng(),
      swGeometry.lng()
    );
  }

  // for a given viewport/bounding box - calculate the length of the diagonal in km,
  // this can be used as a rough measure of how large the geographic search area is
  private calculateBoundingBoxDiagonal(
    geometry: google.maps.places.PlaceGeometry
  ): number {
    const ne = geometry.viewport.getNorthEast();
    const sw = geometry.viewport.getSouthWest();
    return this.calculateDistanceBetweenPoints(
      ne.lat(),
      sw.lat(),
      ne.lng(),
      sw.lng()
    );
  }

  private calculateDistanceBetweenPoints(
    lat1: number,
    lat2: number,
    long1: number,
    long2: number
  ) {
    const c = Math.cos;
    const a =
      0.5 -
      c((lat1 - lat2) * PlaceResultHelper.radiansPerDegree) / 2 +
      (c(lat2 * PlaceResultHelper.radiansPerDegree) *
        c(lat1 * PlaceResultHelper.radiansPerDegree) *
        (1 - c((long1 - long2) * PlaceResultHelper.radiansPerDegree))) /
        2;
    const dis =
      PlaceResultHelper.diameterOfTheEarthInKm * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    return dis;
  }
}
