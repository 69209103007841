import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { Observable } from 'rxjs';
import { TenantDetails } from '../models/tenantModel';

@Injectable({
    providedIn: 'root'
})

export class TenantService extends AbstractService {


    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService
    ) {
        super(httpClientHelper, authenticationService, helper);
    }

    getTenantDetails(): Observable<TenantDetails> {
        return this.httpGetWithoutParamsObs("TenantDetails");
    }
}
