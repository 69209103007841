import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { KeyValue, HttpHelperInitiliseParams } from '../datatypes/misc';
import { AuthenticationService } from '../services/authentication.service';
import { AppConfig } from '../app.config';

@Injectable({ providedIn: 'root' })
export class HttpClientHelper {
    private body: string;
    // Use type object as no predefined type for option and ducumentaion is not clearly explaiend
    // read more https://angular.io/api/common/http/HttpClient#get
    private options: Object;
    private url: string;
    private headers: HttpHeaders;
    private errorOccurred = false;


    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    private initialise(httpHelperParams: HttpHelperInitiliseParams) {
        this.url = AppConfig.getUrl(httpHelperParams.urlKey);
        if (this.url === '' || this.url === undefined) {
            this.errorOccurred = true;
            return;
        }

        // TODO - check that each key value pair is complete
        // id's currently don't have a key - so this is passed in as an empty string to maintain type conformity.
        // FIXME - could/should have key as 'id' so KeyValue would be 'id=6''
        if (this.isArrayPopulated(httpHelperParams.optionalUrlParams)) {
            const urlParams = httpHelperParams.optionalUrlParams.join('/');
            this.url = this.url + urlParams;
        }

        if (this.isArrayPopulated(httpHelperParams.optionalQueryParams)) {
            // add ? query param start sign
            this.url = this.url + '?';

            // append query params to uri and & separator between them
            httpHelperParams.optionalQueryParams.forEach((item) => {
                if (item.value !== undefined) {
                    this.url = this.url + item.key + '=' + item.value + '&';
                }
            });
            // delete last  &separator
            this.url = this.url.substring(0, this.url.length - 1);
        }

        if (httpHelperParams.body) {
            this.body = JSON.stringify(httpHelperParams.body);
        }

        this.createHeaders(httpHelperParams.headerContentType);

        if (httpHelperParams.options) {
            this.options = httpHelperParams.options;
        } else {
            this.options = { headers: this.headers };
        }
    }

    private createHeaders(contentType?: string): HttpHeaders {
        this.headers = new HttpHeaders();
        let contentTypeHdr = 'application/json';
        if (contentType !== undefined || contentType != null) {
            contentTypeHdr = contentType;
        }

        this.headers = this.headers.set('Content-Type', contentTypeHdr)
            .set('Authorization', `Bearer ${this.authenticationService.token}`);

        return this.headers;
    }

    public setOptions(options: Object): void {
        this.options = options;
    }


    public httpGet(httpHelperParams: HttpHelperInitiliseParams): Promise<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.get(this.getUrl(), this.getOptions()).toPromise();
    }

    public httpDelete(httpHelperParams: HttpHelperInitiliseParams): Promise<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.delete(this.getUrl(), this.getOptions()).toPromise();
    }

    public httpDeleteObservable(httpHelperParams: HttpHelperInitiliseParams): Observable<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.delete(this.getUrl(), this.getOptions());
    }

    public httpGetObservable(httpHelperParams: HttpHelperInitiliseParams): Observable<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.get(this.getUrl(), this.getOptions());
    }

    public httpPut(httpHelperParams: HttpHelperInitiliseParams): Promise<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.put(this.getUrl(), this.getBody(), this.getOptions()).toPromise();
    }

    public httpPutObservable(httpHelperParams: HttpHelperInitiliseParams): Observable<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.put(this.getUrl(), this.getBody(), this.options);
    }

    public httpPost(httpHelperParams: HttpHelperInitiliseParams): Observable<any> {
        this.initialise(httpHelperParams);
        return this.httpClient.post(this.getUrl(), this.getBody(), this.getOptions());
    }

    public getBody(): string {
        return this.body;
    }

    public getUrl(): string {
        return this.url;
    }

    public getOptions(): Object {
        return this.options;
    }

    private isArrayPopulated(arr: string[] | KeyValue[]): boolean {
        return (arr !== null && typeof arr !== 'undefined' && arr.length > 0);
    }
}
