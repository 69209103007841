// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-border {
    border: 0;
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}

#addNumberDiv {
    padding-bottom: 10px;
}

@media(min-width:768px) {
    .preferred-wrapper {
        position:relative;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .preferred-label-1 {
        font-size: 0.9rem;
        position: absolute;
        top: -30px;
        text-wrap: nowrap;
    }
}

.preferred-label-2 {
    font-size: 0.9rem;
    margin-right: 16px;
}

.preferred-label-2 {
    font-size: 0.9rem;
    margin-right: 16px;
}

.preferred-input input[type=radio] {
    transform: scale(1.4);
    vertical-align: middle;
    position: absolute;
    top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/account/manage/personal-details/phone-numbers/phone-numbers.component.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB,EAAE,uEAAuE;AAC7F;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,iBAAiB;QACjB,eAAe;QACf,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;QAClB,UAAU;QACV,iBAAiB;IACrB;AACJ;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".no-border {\r\n    border: 0;\r\n    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */\r\n}\r\n\r\n#addNumberDiv {\r\n    padding-bottom: 10px;\r\n}\r\n\r\n@media(min-width:768px) {\r\n    .preferred-wrapper {\r\n        position:relative;\r\n        min-width: 40px;\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n    }\r\n\r\n    .preferred-label-1 {\r\n        font-size: 0.9rem;\r\n        position: absolute;\r\n        top: -30px;\r\n        text-wrap: nowrap;\r\n    }\r\n}\r\n\r\n.preferred-label-2 {\r\n    font-size: 0.9rem;\r\n    margin-right: 16px;\r\n}\r\n\r\n.preferred-label-2 {\r\n    font-size: 0.9rem;\r\n    margin-right: 16px;\r\n}\r\n\r\n.preferred-input input[type=radio] {\r\n    transform: scale(1.4);\r\n    vertical-align: middle;\r\n    position: absolute;\r\n    top: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
