import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './helpers/auth.guard';
import { DiscardChangesGuard } from './helpers/discard-changes.guard';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { ClientsComponent } from './components/clients/clients.component';
import { CandidatesComponent } from './components/candidates/candidates.component';
import { AdviceComponent } from './components/advice/advice.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { TermsOfUseComponent } from './components/policy/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/policy/privacy-policy/privacy-policy.component';
import { FreedomOfInformationActComponent } from './components/policy/freedom-of-information-act/freedom-of-information-act.component';
import { ModernSlaveryActComponent } from './components/policy/modern-slavery-act/modern-slavery-act.component';
import { InfohubComponent } from './components/infohub/infohub.component';
import { UnsubscribeSuccessfulComponent } from './components/unsubscribe-successful/unsubscribe-successful.component';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { EmailConsentComponent } from './components/email-consent/email-consent.component';
import { CookieComponent } from './components/policy/cookies/cookies.component';
import { JobLatestComponent } from './components/job-latest/job-latest.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { AcceptedTermsAndConditionsComponent } from './components/accepted-terms-and-conditions/accepted-terms-and-conditions.component';
import { AccessibilityStatementComponent } from './components/policy/accessibility-statement/accessibility-statement.component';

const routes: Routes = [
    {
        path: 'jobs',
        component: JobLatestComponent
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'about',
        component: AboutComponent
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'clients',
        component: ClientsComponent
    },
    {
        path: 'candidates',
        component: CandidatesComponent
    },
    {
        path: 'applications',
        component: ApplicationsComponent
    },
    {
        path: 'advice',
        component: AdviceComponent
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'privacy-notice',
        component: PrivacyNoticeComponent
    },
    {
        path: 'accepted-terms-and-conditions/:Guid',
        component: AcceptedTermsAndConditionsComponent
    },
    {
        path: 'cookie-policy',
        component: CookieComponent
    },
    {
        path: 'freedom-of-information-act',
        component: FreedomOfInformationActComponent
    },
    {
        path: 'accessibility-statement',
        component: AccessibilityStatementComponent
    },
    {
        path: 'modern-slavery-act',
        component: ModernSlaveryActComponent
    },
    {
        path: 'our-service',
        component: InfohubComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'consent-confirmed/:token',
        component: EmailConsentComponent
    },
    {
        path: 'unsubscribe-successful/:token',
        component: UnsubscribeSuccessfulComponent
    },
    {
        path: 'confirm-password/:email/:token',
        component: ConfirmPasswordComponent
    },
    {
        path: 'search',
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivateChild: [AuthGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'advert-preview/:guid',
        loadChildren: () => import('./modules/job/job.module').then(m => m.JobsModule)
    },
    {
        path: 'job/:name/:id',
        loadChildren: () => import('./modules/job/job.module').then(m => m.JobsModule)
    },
    {
        path: 'saved-jobs',
        loadChildren: () => import('./modules/saved-jobs/saved-jobs.module').then(m => m.SavedJobsModule),
        canActivateChild: [AuthGuard]
    },
    {
        path: 'apply/:name/:id',
        loadChildren: () => import('./modules/apply/apply.module').then(m => m.ApplyModule),
        canActivateChild: [AuthGuard],
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    //relativeLinkResolution: 'corrected'  // no longer needed in V15
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
