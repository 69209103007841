import { Dictionary } from '../helpers/Dictionary';
import { Constant } from './misc';
export class Constants {

    public static readonly SearchPaginationCursor = '*';
    public static readonly SearchResultExpiryTime = 300000;
    public static readonly ViewMaxLettersOfJobDetailsDescription = 300;
    public static readonly MobileScreenBreakingPoint = 768;
    public static readonly DefaultPhoneTypes = ['Home', 'Personal Mobile', 'Work Mobile', 'Other'];

    public static readonly DocumentsFormatAllowed: string[] = ['pdf', 'doc', 'docx'];  // Got this value from file upload component
    public static readonly MaxSizeDocumentAllowed: number = 10485760; // 10mb in binary // HWD-892 agreed this covers CV, application form, Cover Letter ..

    public static readonly dropdownInputDefaultSelect = 'Please select';

    public static readonly dateLocalString = 'en-GB';
    public static readonly CUSTOM_DATE_FORMAT = {
        parse: {
            dateInput: 'DD/MM/YYYY',
        },
        display: {
            dateInput: 'DD/MM/YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'DD/MM/YYYY',
            monthYearA11yLabel: 'MMM YYYY',
        },
    };

    public static readonly GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_TOWN = 'postal_town';
    public static readonly GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_CODE = 'postal_code';
    public static readonly GOOGLE_MAPS_PLACERESULT_TYPE_POSTAL_CODE_PREFIX = 'postal_code_prefix';
    public static readonly GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_1 = 'administrative_area_level_1';
    public static readonly GOOGLE_MAPS_PLACERESULT_TYPE_ADMIN_AREA_LEVEL_2 = 'administrative_area_level_2';

    public static readonly AUTH_ERROR_MESSAGE = 'The username or password is incorrect or account not verified';
    //private static readonly AUTH_SRV_URL = '/token';
    private static readonly AUTH_SRV_URL = '/account/login';

    private static readonly USER_REGISTER_SRV_URL = '/candidate/register';
    private static readonly USER_CONFIRM_SRV_URL = '/account/confirmEmail';
    private static readonly USER_RESENDVERIFICATIION_Email_SRV_URL = '/account/resendConfirmEmail';
    private static readonly USER_RESENDVERIFICATIION_EMAIL_AND_CREATE_JOB_ALERT_SRV_URL = '/account/resendConfirmEmailAndCreateJobAlert';
    private static readonly USER_RESETPASSWORD_SRV_URL = '/account/resetPassword';
    private static readonly USER_RequestPasswordReset_SRV_URL = '/account/forgotPassword';
    private static readonly USER_CHANGEPASSWORD_SRV_URL = '/account/changePassword';
    private static readonly USER_SETPASSWORD_SRV_URL = '/account/setPassword';
    private static readonly USER_CHANGEEMAIL_SRV_URL = '/account/changeEmail';
    private static readonly USER_CHANGEEMAILCONFIRM_SRV_URL = '/account/changeEmailConfirm';
    private static readonly USER_RESENDCHANGEEMAIL_SRV_URL = '/account/resendChangeEmail';
    private static readonly USER_GETUNCONFIRMEDEMAIL_SRV_URL = '/account/getUnConfirmedEmail';
    private static readonly USER_EXISTSUNCONFIRMEDEMAIL_SRV_URL = '/account/existsUnConfirmedEmail';
    private static readonly USER_CLOSECHANGEEMAILREQUEST_SRV_URL = '/account/closeChangeEmailRequest';
    private static readonly USER_RESENDVERIFICATIONEMAIL_SRV_URL = '/account/resendVerificationEmail';
    private static readonly USER_VERIFY_TOKEN_SRV_URL = '/account/verifyToken';

    private static readonly CANDIDATE_UPDATE_SRV_URL = '/candidate/update/';
    private static readonly CANDIDATE_UPDATE_CONSENT_SRV_URL = '/candidate/updateConsent/';
    private static readonly CANDIDATE_EMAILALREADYEXISTS_SRV_URL = '/candidate/emailAlreadyExists/';
    private static readonly CANDIDATE_FETCH_SRV_URL = '/candidate/fetch/';
    private static readonly CANDIDATE_LIST_CVS_SRV_URL = '/candidate/cv/list';
    private static readonly CANDIDATE_DOWNLOAD_CV_SRV_URL = '/cv/Download/';
    private static readonly CANDIDATE_JBE_ACCOUNT_EXISTS_SRV_URL = '/candidate/jbeAccountExists/';
    private static readonly CLIENT_DOWNLOAD_APPLICATION_FORM_SRV_URL = '/clientApplicationForm/download/';
    private static readonly CANDIDATE_DOWNLOAD_APPLICATION_FORM_SRV_URL = '/candidateApplicationForm/download/';
    private static readonly CANDIDATE_DOWNLOAD_COVERLETTER_SRV_URL = '/candidateCoverLetter/download/';
    private static readonly CANDIDATE_DOWNLOAD_FURTHERINFORMATION_SRV_URL = '/furtherInformation/download/';

    private static readonly CANDIDATE_UPLOAD_CV_SRV_URL = '/uploadFile/';
    private static readonly CANDIDATE_UPLOAD_CV_NOT_ALLOWED_SRV_URL = '/candidate/cv/uploadNotAllowed';
    private static readonly CANDIDATE_ADVERT_ALREADY_APPLIED_SRV_URL = '/candidate/alreadyApplied';
    private static readonly CANDIDATE_ADVERT_ALREADY_APPLIED_GUID_SRV_URL = '/candidate/alreadyAppliedGuid';
    private static readonly CANDIDATE_APPLICATIONS_MADE_SRV_URL = '/candidate/applications';

    private static readonly CANDIDATE_SAVE_JOB_SRV_URL = '/candidate/savedJobs/save';
    private static readonly CANDIDATE_REMOVE_SAVED_JOB_SRV_URL = '/candidate/savedJobs/remove';
    private static readonly CANDIDATE_LIST_SAVED_JOBS_SRV_URL = '/candidate/savedJobs/list';
    private static readonly CANDIDATE_EMAIL_CONSENT_CONFIRM = '/candidate/emailConsent';
    private static readonly CANDIDATE_OUTSIDE_UK_EEA_CONSENT_DETAILS = '/candidate/outsideUkEeaConsentDetails';
    private static readonly CANDIDATE_EMAIL_ACCEPTANCE_OF_TSCS_CONFIRM = '/candidate/emailAcceptanceOfTsCs';
    private static readonly CANDIDATE_CONSENT_FOR_ALL_JOBS = '/candidate/consentForAllJobs';
    private static readonly CANDIDATE_ACCEPTANCE_OF_TERMS_AND_CONDITIONS_SETS_CONSENT_FOR_ALL_JOBS = '/candidate/acceptanceOfTermsAndConditionsSetsConsentForAllJobs';

    private static readonly CONSTANTS_LISTBYGROUP_NAME_SRV_URL = '/constants/listByGroupName/';
    private static readonly CONSTANTS_INDUSTRY_SECTORS_NAME_SRV_URL = '/constants/industrySectors/';
    private static readonly CONSTANTS_REFEREE_CONTACT_OPTIONS_SRV_URL = '/constants/refereeContactOptions';

    private static readonly ADVERT_APPLY_SRV_URL = '/candidate/apply';
    private static readonly ADVERT_LIST_SRV_URL = '/advertere/listAll';
    private static readonly ADVERT_LIST_ACTIVE_SRV_URL = '/advertere/listActive';
    private static readonly ADVERT_IS_EQUALITY_DATA_RECORDED_SRV_URL = '/advertere/isEqualityDataRecorded';
    private static readonly ADVERT_LIST_EQUALITY_QUESTIONS_SRV_URL = '/advertere/fetchEqualityQuestions';

    private static readonly USER_FETCH_SRV_URL = '/advertere/fetch/';
    private static readonly USER_FETCH_BY_GUID_SRV_URL = '/advertere/fetchByGuid/';
    private static readonly ADVERT_PREVIEW_FETCH_BY_GUID_SRV_URL = '/advertere/advertPreview/fetchByGuid';

    private static readonly COLLEGE_LIST_SRV_URL = '/listAllColleges';
    private static readonly COURSE_LIST_SRV_URL = '/listAllCoursesByCollege/';
    private static readonly COLLEGE_LOCATION_LIST_SRV_URL = '/listAllCollegeLocationsByCollege/';

    private static readonly JOB_CATEGORYLISTALL_SRV_URL = '/jobCategory/listAll/';
    private static readonly ADVERT_FILTEREDSEARCH_SRV_URL = '/advertereSearch/filteredSearch';

    private static readonly JOBALERT_CREATE_SRV_URL = '/jobAlert/create';
    private static readonly JOBALERT_CREATE_SKELETON_SRV_URL = '/jobAlert/createSkeletonJobAlert';
    private static readonly JOBALERT_LISTALL_SRV_URL = '/jobAlert/listAll';
    private static readonly JOBALERT_UPDATE_SRV_URL = '/jobAlert/update';
    private static readonly JOBALERT_DELETE_SRV_URL = '/jobAlert/delete';
    private static readonly JOBALERT_CHANGESTATUS_SRV_URL = '/jobAlert/changeStatus';
    private static readonly JOBALERT_UNSUBSCRIBE_SRV_URL = '/jobAlert/unsubscribe';
    private static readonly JOBALERT_COUNT_SRV_URL = '/jobAlert/count';
    private static readonly JOBALERT_ONECLICKUNSUBSCRIBE_SRV_URL = '/jobAlert/skeletonUnsubscribe';
    private static readonly JOBALERT_GET_SRV_URL = '/jobAlert/fetch/';

    private static readonly JOB_CandidateDetailsSentOutsideUkEea = '/advertere/candidateDetailsSentOutsideUkEea';

    // Optional Field Settings
    private static readonly OPTIONAL_FIELD_GET_BY_FORM = '/optionalField/form/';
    private static readonly OPTIONAL_FIELD_GET_CONFIGURABLE_FORMS = '/optionalField/forms/';
    private static readonly OPTIONAL_FIELD_UPDATE_FIELDS = '/optionalField/update/';
    private static readonly TENANT_DETAILS = '/tenant/details';

    private static readonly CANDIDATE_DELETE_ACCOUNT_SRV_URL = '/account/deleteAccount';

    // GET Skills and Nationalites
    private static readonly SKILLS_GET_SRV_URL = '/simpleLists/listAllSkills';
    private static readonly NATIONALITIES_GET_SRV_URL = '/simpleLists/listAllNationalities';
    private static readonly QUALIFICATIONS_GET_SRV_URL = '/simpleLists/listAllQualifications';

    public static serviceDictionary = new Dictionary([
        { key: 'AUTH.TOKEN', value: Constants.AUTH_SRV_URL },
        { key: 'Register.Candidate', value: Constants.USER_REGISTER_SRV_URL },
        { key: 'Confirm.Candidate', value: Constants.USER_CONFIRM_SRV_URL },
        { key: 'ResendVerificationEmail.Candidate', value: Constants.USER_RESENDVERIFICATIION_Email_SRV_URL },
        // tslint:disable-next-line: max-line-length
        { key: 'ResendVerificationEmailAndCreateJobAlert.Candidate', value: Constants.USER_RESENDVERIFICATIION_EMAIL_AND_CREATE_JOB_ALERT_SRV_URL },
        { key: 'ResetPassword.Candidate', value: Constants.USER_RESETPASSWORD_SRV_URL },
        { key: 'RequestPasswordReset.Candidate', value: Constants.USER_RequestPasswordReset_SRV_URL },
        { key: 'Password.Change', value: Constants.USER_CHANGEPASSWORD_SRV_URL },
        { key: 'Password.Set', value: Constants.USER_SETPASSWORD_SRV_URL },
        { key: 'Candidate.ChangeEmail', value: Constants.USER_CHANGEEMAIL_SRV_URL },
        { key: 'Confirm.EmailChange', value: Constants.USER_CHANGEEMAILCONFIRM_SRV_URL },
        { key: 'Account.VerifyToken', value: Constants.USER_VERIFY_TOKEN_SRV_URL },

        { key: 'Candidate.AlreadyApplied', value: Constants.CANDIDATE_ADVERT_ALREADY_APPLIED_SRV_URL },
        { key: 'Candidate.AlreadyAppliedGuid', value: Constants.CANDIDATE_ADVERT_ALREADY_APPLIED_GUID_SRV_URL },
        { key: 'Candidate.Applications', value: Constants.CANDIDATE_APPLICATIONS_MADE_SRV_URL },
        { key: 'Candidate.SaveJob', value: Constants.CANDIDATE_SAVE_JOB_SRV_URL },
        { key: 'Candidate.RemoveSavedJob', value: Constants.CANDIDATE_REMOVE_SAVED_JOB_SRV_URL },
        { key: 'Candidate.ListSavedJobs', value: Constants.CANDIDATE_LIST_SAVED_JOBS_SRV_URL },
        { key: 'Candidate.Update', value: Constants.CANDIDATE_UPDATE_SRV_URL },
        { key: 'Candidate.UpdateConsent', value: Constants.CANDIDATE_UPDATE_CONSENT_SRV_URL },
        { key: 'Candidate.EmailAlreadyExists', value: Constants.CANDIDATE_EMAILALREADYEXISTS_SRV_URL },
        { key: 'Candidate.ResendChangeEmailVerification', value: Constants.USER_RESENDCHANGEEMAIL_SRV_URL },
        { key: 'Candidate.UnConfirmedEmail', value: Constants.USER_GETUNCONFIRMEDEMAIL_SRV_URL },
        { key: 'Candidate.ExistsUnConfirmedEmail', value: Constants.USER_EXISTSUNCONFIRMEDEMAIL_SRV_URL },
        { key: 'Candidate.CloseChangeEmailRequest', value: Constants.USER_CLOSECHANGEEMAILREQUEST_SRV_URL },
        { key: 'Candidate.Fetch', value: Constants.CANDIDATE_FETCH_SRV_URL },
        { key: 'Candidate.JbeAccountExists', value: Constants.CANDIDATE_JBE_ACCOUNT_EXISTS_SRV_URL },
        { key: 'Candidate.EmailConsent', value: Constants.CANDIDATE_EMAIL_CONSENT_CONFIRM },
        { key: 'Candidate.OutsideUkEeaConsentDetails', value: Constants.CANDIDATE_OUTSIDE_UK_EEA_CONSENT_DETAILS },
        { key: 'Candidate.EmailAcceptanceOfTsCs', value: Constants.CANDIDATE_EMAIL_ACCEPTANCE_OF_TSCS_CONFIRM },
        { key: 'Candidate.ConsentForAllJobs', value: Constants.CANDIDATE_CONSENT_FOR_ALL_JOBS },
        { key: 'Candidate.AcceptanceOfTermsAndConditionsSetsConsentForAllJobs', value: Constants.CANDIDATE_ACCEPTANCE_OF_TERMS_AND_CONDITIONS_SETS_CONSENT_FOR_ALL_JOBS },

        { key: 'Account.ResendVerificationEmail', value: Constants.USER_RESENDVERIFICATIONEMAIL_SRV_URL },

        { key: 'Account.Delete', value: Constants.CANDIDATE_DELETE_ACCOUNT_SRV_URL },

        { key: 'Candidate.CvList', value: Constants.CANDIDATE_LIST_CVS_SRV_URL },
        { key: 'Candidate.CvDownload', value: Constants.CANDIDATE_DOWNLOAD_CV_SRV_URL },
        { key: 'Candidate.CvUploadNotAllowed', value: Constants.CANDIDATE_UPLOAD_CV_NOT_ALLOWED_SRV_URL },
        { key: 'DownloadClientApplicationForm', value: Constants.CLIENT_DOWNLOAD_APPLICATION_FORM_SRV_URL },
        { key: 'DownloadCandidateApplicationForm', value: Constants.CANDIDATE_DOWNLOAD_APPLICATION_FORM_SRV_URL },
        { key: 'Candidate.CoverLetterDownload', value: Constants.CANDIDATE_DOWNLOAD_COVERLETTER_SRV_URL },
        { key: 'FurtherInformationDownload', value: Constants.CANDIDATE_DOWNLOAD_FURTHERINFORMATION_SRV_URL },

        { key: 'Advert.Apply', value: Constants.ADVERT_APPLY_SRV_URL },

        { key: 'Advert.List', value: Constants.ADVERT_LIST_SRV_URL },
        { key: 'Advert.ListActive', value: Constants.ADVERT_LIST_ACTIVE_SRV_URL },
        { key: 'Advert.IsEqualityDataRecorded', value: Constants.ADVERT_IS_EQUALITY_DATA_RECORDED_SRV_URL },
        { key: 'Advert.ListEqualityQuestions', value: Constants.ADVERT_LIST_EQUALITY_QUESTIONS_SRV_URL },
        { key: 'Advert.Fetch', value: Constants.USER_FETCH_SRV_URL },
        { key: 'Advert.FetchByGuid', value: Constants.USER_FETCH_BY_GUID_SRV_URL },
        { key: 'AdvertPreview.FetchByGuid', value: Constants.ADVERT_PREVIEW_FETCH_BY_GUID_SRV_URL},
        { key: 'Advert.FilteredSearch', value: Constants.ADVERT_FILTEREDSEARCH_SRV_URL },

        { key: 'College.List', value: Constants.COLLEGE_LIST_SRV_URL },
        { key: 'Course.List', value: Constants.COURSE_LIST_SRV_URL },
        { key: 'CollegeLocation.List', value: Constants.COLLEGE_LOCATION_LIST_SRV_URL },

        { key: 'Document.Upload', value: Constants.CANDIDATE_UPLOAD_CV_SRV_URL },
        { key: 'Constants.ListByGroupName', value: Constants.CONSTANTS_LISTBYGROUP_NAME_SRV_URL },
        { key: 'Job.Categories', value: Constants.JOB_CATEGORYLISTALL_SRV_URL },

        { key: 'Constants.GetIndustrySectors', value: Constants.CONSTANTS_INDUSTRY_SECTORS_NAME_SRV_URL },
        { key: 'Constants.GetRefereeContactOption', value: Constants.CONSTANTS_REFEREE_CONTACT_OPTIONS_SRV_URL },

        { key: 'OptionalField.GetOptionalFieldsByForm', value: Constants.OPTIONAL_FIELD_GET_BY_FORM },
        { key: 'OptionalField.GetConfigurableForms', value: Constants.OPTIONAL_FIELD_GET_CONFIGURABLE_FORMS },
        { key: 'OptionalField.UpdateOptionalFields', value: Constants.OPTIONAL_FIELD_UPDATE_FIELDS },

        { key: 'JobAlert.Create', value: Constants.JOBALERT_CREATE_SRV_URL },
        { key: 'JobAlert.CreateSkeleton', value: Constants.JOBALERT_CREATE_SKELETON_SRV_URL },
        { key: 'JobAlert.ListAll', value: Constants.JOBALERT_LISTALL_SRV_URL },
        { key: 'JobAlert.Update', value: Constants.JOBALERT_UPDATE_SRV_URL },
        { key: 'JobAlert.Delete', value: Constants.JOBALERT_DELETE_SRV_URL },
        { key: 'JobAlert.ChangeStatus', value: Constants.JOBALERT_CHANGESTATUS_SRV_URL },
        { key: 'JobAlert.Unsubscribe', value: Constants.JOBALERT_UNSUBSCRIBE_SRV_URL },
        { key: 'JobAlert.Count', value: Constants.JOBALERT_COUNT_SRV_URL },
        { key: 'JobAlert.OneClickUnsubscribe', value: Constants.JOBALERT_ONECLICKUNSUBSCRIBE_SRV_URL },
        { key: 'TenantDetails', value: Constants.TENANT_DETAILS },
        { key: 'JobAlert.OneClickUnsubscribe', value: Constants.JOBALERT_ONECLICKUNSUBSCRIBE_SRV_URL },
        { key: 'JobAlert.Get', value: Constants.JOBALERT_GET_SRV_URL },

        { key: 'Advert.candidateDetailsSentOutsideUkEea', value: Constants.JOB_CandidateDetailsSentOutsideUkEea},

        { key: 'Common.Skills.ListAll', value: Constants.SKILLS_GET_SRV_URL },
        { key: 'Common.Nationalities.ListAll', value: Constants.NATIONALITIES_GET_SRV_URL },
        { key: 'Common.Qualifications.ListAll', value: Constants.QUALIFICATIONS_GET_SRV_URL }


    ]).toLookup();
}
