import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';

@Component({
    selector: 'custom-modal',
    templateUrl: './custom-modal.component.html',
    styles: ['.container { min-height: calc(100vh - 436px);} h1 { margin-top: 40px;}']
})
export class CustomModalComponent implements OnInit {

    @Input()
    public modalId: string;

    @Input()
    public title: string;

    @Input()
    public body: string;

    @ViewChild('closeModalBtn', { static: false })
    public closeModalBtn: ElementRef;

    titleText: string;
    bodyText: string;
    btnText: string;

    ngOnInit() {
        this.initText();
    }

    private initText() {
        this.btnText = 'OK';
    }
}
