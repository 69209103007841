import { Component, OnInit } from '@angular/core';

import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'advice',
    templateUrl: './advice.component.html',
    styleUrls: ['./advice.component.css']
})
export class AdviceComponent implements OnInit {

    public advicePageContent = '';

    constructor(private helpersService: HelpersService) { }

    ngOnInit() {
        this.advicePageContent = this.helpersService.getAdvicePageContent();
    }
}
