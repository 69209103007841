import { Directive, HostListener } from '@angular/core';
import {  AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: "[TrimAndRemoveHyphens-directive]"
})
export class TrimAndRemoveHyphensDirective {
  private ctrl: AbstractControl;
  
  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.ctrl = this.ngControl.control;
  }

  transformValue(value: string) : string {
    return value.trim().replace(/^-+|-+$/g, '').trim();
  }

  @HostListener("blur", ["$event.target.value"])
  ngModelChange(value: any) {
    // only transform value if the model is dirty, this avoids 
    // setting the model as dirty if the user has not made any 
    // changes, i.e. they have just clicked on the field and then moved away
    if (this.ctrl.dirty) {
      const transformedValue = this.transformValue(value);
      if (value !== transformedValue) {
        this.ctrl.setValue(transformedValue)
      } 
    }   
  }
}

