import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AbstractService } from 'src/app/services/abstract.service';
import { HttpClientHelper } from 'src/app/helpers/HttpClientHelper';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/helpers/helpers.service';
import { HttpHelperInitiliseParams } from 'src/app/datatypes/misc';
import { IJobAlert, JobAlert } from '../models';
import { SkeletonJobAlert } from 'src/app/components/search/jbe-custom-modal/models';

@Injectable()
export class JobAlertService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService) {
            super(httpClientHelper, authenticationService, helper);
        }

    create(dto: IJobAlert) {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'JobAlert.Create', dto);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(catchError(this.helper.handleErrorObservable));
    }

    createSkeletonJobAlert(dto: SkeletonJobAlert) {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'JobAlert.CreateSkeleton', dto);

        return this.httpClientHelper.httpPost(httpHelperParams);
    }

    get(id: number): Observable<JobAlert> {
        return this.httpGetWithParamsObs('JobAlert.Get', [id.toString()]);
    }

    getAll(): Observable<JobAlert[]> {
        return this.httpGetWithoutParamsObs<JobAlert[]>('JobAlert.ListAll');
    }

    edit(dto: IJobAlert) {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'JobAlert.Update', dto);

        return this.httpClientHelper.httpPutObservable(httpHelperParams).pipe(catchError(this.helper.handleErrorObservable));
    }

    delete(id: number) {
        const httpHelperParams = new HttpHelperInitiliseParams([`/${id}`], [], 'JobAlert.Delete');

        return this.httpClientHelper.httpDeleteObservable(httpHelperParams).pipe(catchError(this.helper.handleErrorObservable ));
    }

    changeStatus(obj: any) {
        const httpHelperParams = new HttpHelperInitiliseParams([`/${obj.JobAlertId}/${obj.Value}`], [], 'JobAlert.ChangeStatus');

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(catchError(this.helper.handleErrorObservable));
    }

    unsubscribe() {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'JobAlert.Unsubscribe');

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(catchError(this.helper.handleErrorObservable));
    }

    getCount(): Observable<number> {
        return this.httpGetWithoutParamsObs<number>('JobAlert.Count').pipe(catchError(this.helper.handleErrorObservable));
    }
}
