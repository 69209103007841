import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { AbstractService } from "./abstract.service";
import { HelpersService } from "../helpers/helpers.service";
import { HttpHelperInitiliseParams } from "../datatypes/misc";
import { HttpClientHelper } from "../helpers/HttpClientHelper";
import { OptionalFieldSettings, FormSettings } from "../models/optionalFieldSettings.model";

@Injectable()
export class OptionalFieldService extends AbstractService {

    constructor(protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService) {
        super(httpClientHelper, authenticationService, helper);
    }

    public getFormOptionalFields(formName: string): Promise<FormSettings> {
        return this.httpGetWithParams<FormSettings>("OptionalField.GetOptionalFieldsByForm", [formName]);
    }

    public getConfigurableForms(): Promise<FormSettings[]> {
        return this.httpGetWithoutParams<FormSettings[]>("OptionalField.GetConfigurableForms");
    }

    public updateOptionalFields(optionalFields: OptionalFieldSettings[]): Promise<boolean> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], "OptionalField.UpdateOptionalFields", optionalFields);
        return this.httpClientHelper.httpPut(httpHelperParams)
            .then(result => result)
            .catch(this.helper.handleErrorPromise);
    }

}