import { JobAlertCriteria } from './job-alert-criteria.model';
import { IJobAlert } from './interfaces/IJobAlert.model';
import { EJobAlertStatusEnum } from '../enums/EJobAlertStatusEnum';
import { EAdvertSortOrder } from 'src/app/datatypes/misc';

export class JobAlert implements IJobAlert {
    JobAlertId: number;
    Title: string;
    Criteria: JobAlertCriteria[];
    Status: EJobAlertStatusEnum;
    AdvertSortOrder?: EAdvertSortOrder;

    constructor( title: string, criteria: JobAlertCriteria[], status: EJobAlertStatusEnum, advertSortOrder?: EAdvertSortOrder) {
        this.Title = title,
        this.Criteria = criteria;
        this.Status = status;
        this.AdvertSortOrder = advertSortOrder;
    }
}

