import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { CandidateFields } from "../../../../datatypes/fields/candidate-fields.constants";
import { Dictionary } from "../../../../helpers/Dictionary";
import { Candidate } from "../../../../models/candidate.model";
import { FormSettings } from "../../../../models/optionalFieldSettings.model";
import { FormSettingsHelperAbstractComponent } from "../form-settings-helper.abstract.component";

@Component({
    selector: 'dob-input',
    templateUrl: './dob-input.component.html'
})

export class DateOfBirthInputComponent extends FormSettingsHelperAbstractComponent implements OnInit, AfterViewInit{


    @ViewChildren(NgModel) controls: QueryList<NgModel>;
    @Output() onComponentChange = new EventEmitter<boolean>();
    @Input() model: Candidate;
    @Input() formSettings: FormSettings;
    @Input() submited: boolean;
   
    startDate = new Date();
    isBirthDateOk: boolean;

    constructor(private parentForm: NgForm) {
        super()
    }

    ngOnInit() {
        this.startDate.setFullYear(this.startDate.getFullYear() - 15);
        this.isValidDateOfBirth();
    }

    ngAfterViewInit() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.addControl(control);
        });
    }

    isValidDateOfBirth() {
        this.isBirthDateOk = !!this.model.Personal.DateOfBirth || !this.isRequired(CandidateFields.birthDate);
        this.isComponentValid();
        return this.isBirthDateOk;
    }

    isComponentValid() {
        let validators = new Dictionary([
            { key: 'Birth Date', value: this.isBirthDateOk }
        ]);

        if (!validators['Birth Date']) {
            this.onComponentChange.emit(false);
            return;
        }

        this.onComponentChange.emit(true);
    }

}