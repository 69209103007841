import { Observable, BehaviorSubject } from 'rxjs';

import { CurrentUser } from '../models/current-user.model';

export class LocalStorageService {

    private logger = new BehaviorSubject<boolean>(false);
    private loggedIn = false;

    setCurrentUser(user: CurrentUser) {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.loggedIn = true;
        this.logger.next(this.loggedIn);
    }

    getCurrentUser(): CurrentUser {
        const currentUser = new CurrentUser();
        const user = JSON.parse(sessionStorage.getItem('currentUser') || '{}');

        currentUser.token = user.token;
        currentUser.expiration = new Date(user['expiration']);

        //currentUser.access_token = user.access_token;
        //currentUser.access_token = user.access_token;
        //currentUser.userName = user.userName;
        //currentUser.expires_in = user.expires_in;
        //currentUser.token_type = user.token_type;
        //currentUser.expires = new Date(user['.expires']);
        //currentUser.issued = new Date(user['.issued']);

        return currentUser;
    }

    clear(): void {
        sessionStorage.clear();
        this.loggedIn = false;
        this.logger.next(this.loggedIn);
    }

    // used to verify if the session storage entries are still valid
    isUserStillActive(): boolean {
        if (sessionStorage.getItem('currentUser') && !this.isTokenExpired()) {
            this.loggedIn = true;
            this.logger.next(this.loggedIn);
            return true;
        }
        return false;
    }

    isLoggedInObs(): Observable<boolean> {
        return this.logger.asObservable();
    }

    private isTokenExpired(): boolean {
        const actualDate = new Date().getTime();
        const tokenDate = this.getCurrentUser().expiration.getTime();

        // console.log("token:" + tokenDate + " < date:" + actualDate);

        if (tokenDate < actualDate) {
            return true;
        }
        return false;
    }
}
