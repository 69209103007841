export class CurrentUser {
    public token: string;
    public expiration: Date;
    //public access_token: string;
    //public token_type: string;
    //public expires_in: number;
    //public userName: string;
    //public issued: Date;
    //public expires: Date;
}
