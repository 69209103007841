import { Component, OnInit } from '@angular/core';
import { LabelHelper } from '../../helpers/label.helper';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    public isBrowserSupported = true;

    constructor() {
        LabelHelper.initialize();
    }

    ngOnInit() {
        this.isBrowserSupported = this.browserCheck();
    }

    // currently use to show upload message on IE 11
    private browserCheck(): boolean {
        const ua = window.navigator.userAgent;
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
             // IE 11 => return version number
            return false;
        }
        return true;
    }
}
