import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ValidationConstants } from '../../datatypes/validation.constants';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-confirm-password',
    templateUrl: './confirm-password.component.html',
    styleUrls: ['./confirm-password.component.css']
})
export class ConfirmPasswordComponent implements OnInit {

    hidePassword = true;
    passwordValidation = ValidationConstants.password;
    model = {
        Password: '',
        PasswordConfirm: ''
    };

    email: string;
    token: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private accountService: AccountService) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.email = params.email;
            this.token = params.token;
        });
    }

    toggleShowPass() {
        this.hidePassword = !this.hidePassword;
    }

    setPassword() {
        const dto = {
            email: this.email,
            token: this.token,
            password: this.model.Password,
            confirmPassword: this.model.PasswordConfirm
        };

        this.accountService.setPassword(dto)
            .subscribe(res => {
                this.router.navigate(['/account/login']);
            });
    }

    passwordsDoNotMatch(): boolean {
        return this.model.Password === this.model.PasswordConfirm;
    }
}
