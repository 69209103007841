import { JobAlertCriteria } from '../job-alert-criteria.model';
import { EJobAlertStatusEnum } from '../../enums/EJobAlertStatusEnum';
import { EAdvertSortOrder } from 'src/app/datatypes/misc';

export interface IJobAlert {
    JobAlertId: number;
    Title: string;
    Criteria: JobAlertCriteria[];
    Status: EJobAlertStatusEnum;
    AdvertSortOrder?: EAdvertSortOrder;
}
