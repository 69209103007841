// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group.password-div {
    position: relative;
}

.form-group.password-div span.password-Span {
    color: #999;
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 17px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-password/confirm-password.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".form-group.password-div {\r\n    position: relative;\r\n}\r\n\r\n.form-group.password-div span.password-Span {\r\n    color: #999;\r\n    cursor: pointer;\r\n    position: absolute;\r\n    top: 40px;\r\n    right: 17px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
