import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService, ToastrModule } from 'ngx-toastr';

import { MaterialModule } from './material.module';
import { CustomModalComponent } from '../components/common/modal/custom-modal.component';

import { LocalStorageService } from '../services/local-storage.service';
import { HelpersService } from '../helpers/helpers.service';

import { AuthGuard } from '../helpers/auth.guard';
import { DiscardChangesGuard } from '../helpers/discard-changes.guard';
import { TruncatePipe } from '../components/common/truncate-pipe';
import { SanitizeHtmlPipe } from '../components/common/sanitize-html-pipe';
import { UppercaseDirective } from '../components/common/uppercase-directive';
import { ConfirmEqualValidatorDirective } from '../components/common/confirm-equal-validator.directive';
import { PostCodeValidatorDirective } from '../components/common/postcode-validation.directive';
import { MultiSelectComponent } from '../components/common/multiselect/multiselect.component';
import { ValidationConstants } from '../datatypes/validation.constants';
import { ChangeEmailModalComponent } from '../components/account/change-email-address/change-email-modal/changeEmailModal.component';
import { SalaryComponent } from '../components/job/salary/salary.component';
import { AddressComponent } from '../components/account/manage/address/address.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { DeleteAccountComponent } from '../components/deleteaccount/deleteaccount.component';
import { SavedJobsIndicatorComponent } from '../components/savedJobs/savedJobs-indicator/savedJobs-indicator.component';
import { CandidateService } from '../services/candidate.service';
import { LocationSearchComponent } from '../components/search/location/locationsearch.component';
import { AuthenticationService } from '../services/authentication.service';
import { SearchFormSharedService } from '../components/search/searchForm/searchForm.shared.service';
import { JbeCustomModalComponent } from '../components/search/jbe-custom-modal/jbe-custom-modal.component';
import { PersonalDetailsComponent } from '../components/account/manage/personal-details/personal-details.component';
import { DateOfBirthInputComponent } from '../components/account/manage/about-you/dob-input.component';
import { EmploymentComponent } from '../components/account/manage/employment/employment.component';
import { RemovalOfConsentComponent } from '../components/account/manage/consent/removalofconsent.component';
import { NumberInputDirective } from '../directives/number-input/number-input.directive';
import { TrimInputDirective } from '../directives/trimInput/trimInput.directive';
import { TrimAndRemoveHyphensDirective } from '../directives/trimAndRemoveHyphens/trimAndRemoveHyphens.directive ';
import { PhoneNumbersComponent } from '../components/account/manage/personal-details/phone-numbers/phone-numbers.component';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSpinnerModule
];

const components = [
    CustomModalComponent,
    MultiSelectComponent,
    SanitizeHtmlPipe,
    TruncatePipe,
    UppercaseDirective,
    ConfirmEqualValidatorDirective,
    PostCodeValidatorDirective,
    ChangeEmailModalComponent,
    SalaryComponent,
    AddressComponent,
    FileUploadComponent,
    DeleteAccountComponent,
    SavedJobsIndicatorComponent,
    ChangeEmailModalComponent,
    LocationSearchComponent,
    JbeCustomModalComponent,
    PersonalDetailsComponent,
    DateOfBirthInputComponent,
    EmploymentComponent,
    RemovalOfConsentComponent,
    NumberInputDirective,
    TrimInputDirective,
    TrimAndRemoveHyphensDirective,
    PhoneNumbersComponent
];

@NgModule({
    declarations: [
        components
    ],
    imports: [
        modules,
        ToastrModule.forRoot()],
    exports: [
        modules,
        components
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AuthGuard,
                DiscardChangesGuard,
                ToastrService,
                HelpersService,
                LocalStorageService,
                CandidateService,
                ValidationConstants,
                AuthenticationService,
                SearchFormSharedService
            ]
        };
    }
 }
