export enum JbeModalTypes {
    Create = 1,
    Confirmation = 2,
    Error = 3,
    CreateSkeleton = 4,
    SkeletonConfirm = 5,
    MaxReached = 6,
    ResendVerificationEmail = 7,
    CreateAccount = 8
}
