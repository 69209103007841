import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HelpersService } from '../../helpers/helpers.service';
import { TenantService } from '../../services/tenant.service';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { CandidateService } from '../../services/candidate.service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'accepted-terms-and-conditions',
    templateUrl: './accepted-terms-and-conditions.component.html'
})
export class AcceptedTermsAndConditionsComponent implements OnInit {

    tenantName = '';
    tenantNumber = '';
    acceptanceOfTsCsConfirmSuccessful: boolean = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private candidateService: CandidateService,
        private tenantService: TenantService,
        public toastr: ToastrService
    ) { }

    ngOnInit() {
        this.tenantService.getTenantDetails()
            .pipe(
                map(tenant => {
                    this.tenantName = tenant.TenantDisplayName;
                    this.tenantNumber = tenant.TenantContactNumber;
                }),
                switchMapTo(this.activatedRoute.params),
                switchMap(params => this.candidateService.confirmEmailAcceptanceOfTsCs(params.Guid))
            ).subscribe(res => {
                this.acceptanceOfTsCsConfirmSuccessful = res;
            });
    }
}
