import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

import { AppConfig } from '../app.config';
import { CorporateConfig } from '../corporate.config';

// NO validation in this HelpersService please.
@Injectable()
export class HelpersService {

    private apiBaseUrl: string;

    // TODO remove, have common service for this in hero
    // common service api need separating from hero for use here.
    // not going to duplicate in Herojobs
    getCountries(): string[] {
        let CountriesList: string[] = [];

        if (this.apiBaseUrl === undefined) {
            CountriesList = AppConfig.Countries;
        }
        return CountriesList;
    }

    getCountys(): string[] {

        let CountyList: string[] = [];

        if (this.apiBaseUrl === undefined) {
            CountyList = AppConfig.Countys;
        }
        return CountyList;
    }

    getCommunicationCategories(): string[] {

        let CommunicationCategories: string[] = [];

        if (this.apiBaseUrl === undefined) {
            CommunicationCategories = AppConfig.CommunicationCategories;
        }

        return CommunicationCategories;
    }

    getCommunicationMethods(): string[] {

        let CommunicationMethods: string[] = [];

        if (this.apiBaseUrl === undefined) {
            CommunicationMethods = AppConfig.CommunicationMethods;
        }

        return CommunicationMethods;
    }

    getApiBaseUrl(): string {
        if (this.apiBaseUrl === undefined) {
            this.apiBaseUrl = AppConfig.apiPath;
        }
        return this.apiBaseUrl;
    }

    getProximityDistance(): number[] {
        let ProximityDistance: number[] = [];

        if (this.apiBaseUrl === undefined) {
            ProximityDistance = AppConfig.ProximityDistance;
        }

        return ProximityDistance;
    }

    handleErrorPromise(error: any): Promise<any> {
        if (error.status === 404) {
            return Promise.resolve(`An error occurred: ${error.status} ${error.statusText}`);
        }
        return Promise.reject(error.message || error);
    }

    handleErrorObservable(error: any) {
        console.error('post error', error);
        return throwError(error.statusText);
    }

    extractData(res: any) {
        const body = res;
        return body || {};
    }

    extractData1(res: HttpResponse<any>) {
        const body = res;
        return body || {};
    }

    handleError(error: any) {
        console.error('post error', error);
        return throwError(error.statusText);
    }

    remove(value: string, orginalList: string[], choosenList: string[]) {
        orginalList.push(value);
        choosenList.splice(choosenList.indexOf(value), 1);
    }

    add(value: string, orginalList: string[], choosenList: string[]) {
        orginalList.splice(orginalList.indexOf(value), 1);
        choosenList.push(value);
    }



    getPageContent(corporateConfig: string): string {
        let PageContent = '';

        if (this.apiBaseUrl === undefined) {
            PageContent = corporateConfig;
        }
        return PageContent;
    }
    // Below needs to be refactored - one method will do. use above
    getCandidatePageContent(): string {
        let candidatePageContent = '';

        if (this.apiBaseUrl === undefined) {
            candidatePageContent = CorporateConfig.candidatePage;
        }
        return candidatePageContent;
    }

    getAdvicePageContent(): string {
        let advicePageContent = '';

        if (this.apiBaseUrl === undefined) {
            advicePageContent = CorporateConfig.advicePage;
        }
        return advicePageContent;
    }

    getClientsPageContent(): string {
        let clientsPageContent = '';

        if (this.apiBaseUrl === undefined) {
            clientsPageContent = CorporateConfig.clientsPage;
        }
        return clientsPageContent;
    }

    getContactPageContent(): string {
        let contactPageContent = '';

        if (this.apiBaseUrl === undefined) {
            contactPageContent = CorporateConfig.contactPage;
        }
        return contactPageContent;
    }

    getAboutPageContent(): string {
        let aboutPageContent = '';

        if (this.apiBaseUrl === undefined) {
            aboutPageContent = CorporateConfig.aboutPage;
        }
        return aboutPageContent;
    }

    getTermsOfUsePageContent(): string {
        let termsOfUsePageContent = '';

        if (this.apiBaseUrl === undefined) {
            termsOfUsePageContent = CorporateConfig.termsOfUsePage;
        }
        return termsOfUsePageContent;
    }

    getPrivacyPolicyPageContent(): string {
        let privacyPolicyPageContent = '';

        if (this.apiBaseUrl === undefined) {
            privacyPolicyPageContent = CorporateConfig.privacyPolicyPage;
        }
        return privacyPolicyPageContent;
    }

    getAcceptedTermsAndConditionsPageContent(): string {
        let acceptedTermsAndConditionsPageContent = '';

        if (this.apiBaseUrl === undefined) {
            acceptedTermsAndConditionsPageContent = CorporateConfig.acceptanceofTermsandConditions;
        }
        return acceptedTermsAndConditionsPageContent;
    }

    getModernSlaveryActPageContent(): string {
        let modernSlaveryActPageContent = '';

        if (this.apiBaseUrl === undefined) {
            modernSlaveryActPageContent = CorporateConfig.modernSlaveryActPage;
        }
        return modernSlaveryActPageContent;
    }

    getFreedomOfInformationActPageContent(): string {
        let freedomOfInformationActPageContent = '';

        if (this.apiBaseUrl === undefined) {
            freedomOfInformationActPageContent = CorporateConfig.freedomOfInformationActPage;
        }
        return freedomOfInformationActPageContent;
    }

    getInfoHubPageContent(): string {
        let infoHubPageContent = '';

        if (this.apiBaseUrl === undefined) {
            infoHubPageContent = CorporateConfig.infoHubPage;
        }
        return infoHubPageContent;
    }

    getFooterSubLogoText(): string {
        let footerSubLogoText = '';

        if (this.apiBaseUrl === undefined) {
            footerSubLogoText = CorporateConfig.footerSubLogoText;
        }
        return footerSubLogoText;
    }

    getFooterCopyRightText(): string {
        let footerCopyRightText = '';

        if (this.apiBaseUrl === undefined) {
            footerCopyRightText = CorporateConfig.footerCopyRightText;
        }
        return footerCopyRightText;
    }
}
