import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CorporateConfig } from 'src/app/corporate.config';

import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'candidates',
    templateUrl: './candidates.component.html',
    styleUrls: ['./candidates.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CandidatesComponent implements OnInit {

    candidatePageContent: string;
    displayCandidatePageTitle: boolean;

    constructor(
        private helpersService: HelpersService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.candidateTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.candidateDescription});
     }

    ngOnInit() {
        this.candidatePageContent = this.helpersService.getCandidatePageContent();
        this.displayCandidatePageTitle = this.helpersService.getPageContent(CorporateConfig.displayCandidatePageTitle)?.toLowerCase() === 'true' ? true : false;
    }
}
