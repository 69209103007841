import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { CorporateConfig } from 'src/app/corporate.config';
import { HelpersService } from '../../helpers/helpers.service';
import { TenantService } from '../../services/tenant.service';


@Component({
    selector: 'privacy-notice',
    templateUrl: './privacy-notice.component.html'
})
export class PrivacyNoticeComponent implements OnInit {

    tenantName = '';
    public privacyPolicyPageContent = '';

    constructor(
        private router: Router,
        private helpersService: HelpersService,
        private tenantService: TenantService,
        private metaService: Meta,
        private titleService: Title
    ) { 
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.privacyNoticeTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.privacyNoticeDescription});
    }

    ngOnInit() {
        this.privacyPolicyPageContent = this.helpersService.getPageContent(CorporateConfig.privacyNoticePage);
        this.tenantService.getTenantDetails()
            .subscribe(tenant => this.tenantName = tenant.TenantDisplayName);
    }
}
