// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    padding-top: 40px;
    min-height: calc(100vh - 436px);
}

.noWrap {
    white-space: nowrap
}

.table {
    text-align: center;
}

td a {
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/applications/applications.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".container {\r\n    padding-top: 40px;\r\n    min-height: calc(100vh - 436px);\r\n}\r\n\r\n.noWrap {\r\n    white-space: nowrap\r\n}\r\n\r\n.table {\r\n    text-align: center;\r\n}\r\n\r\ntd a {\r\n    text-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
