import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Constants } from './datatypes/constants';
import { ServiceOperations } from './datatypes/service-operations';


@Injectable()
export class AppConfig {

    constructor(private httpClient: HttpClient) { }

    static get title(): string {
        return AppConfig.data.title;
    }

    static get apiPath(): string {
        return AppConfig.data.heroapi;
    }

    static get release(): string {
        return AppConfig.data.releaseNumber;
    }

    static get isProdBuild(): boolean {
        return AppConfig.data.isProdBuild;
    }

    static get isDevBuild(): boolean {
        return !AppConfig.data.isProdBuild;
    }

    static get Countries(): string[] {
        return AppConfig.data.Countries;
    }

    static get Countys(): string[] {
        return AppConfig.data.County;
    }

    static get Nationalities(): string[] {
        return AppConfig.data.Nationalities;
    }

    static get Sectors(): string[] {
        return AppConfig.data.Sectors;
    }

    static get Skills(): string[] {
        return AppConfig.data.Skills;
    }

    static get CommunicationMethods(): string[] {
        return AppConfig.data.CommunicationMethods;
    }
    static get CommunicationCategories(): string[] {
        return AppConfig.data.CommunicationCategories;
    }

    static get ProximityDistance(): number[] {
        return AppConfig.data.ProximityDistance;
    }

    static get UseEnhancedSearch(): boolean {
        return AppConfig.data.UseEnhancedSearch;
    }

    // if true, large areas selected by the user will disable the distance selector
    static get UseSearchAreaDistanceControl(): boolean {
        return AppConfig.data.UseSearchAreaDistanceControl;
    }

    static get DefaultDistance(): number {
        return AppConfig.data.DefaultDistance;
    }

    static get tenantName(): string {
        return AppConfig.data.Tenant;
    }

    static get UseCoverLetterTextField(): boolean {
        return AppConfig.data.UseCoverLetterTextField;
    }

    static get ValidateRefereesOnProfileCompleteness(): boolean {
        return AppConfig.data.ValidateRefereesOnProfileCompleteness;
    }

    static get displayCriminalConvictionsOnApply(): boolean {
        return AppConfig.data.displayCriminalConvictionsOnApply;
    }

    private static data: Config;

    private static readonly AUTH_SRV_URL = '/token';
    private static readonly COMMON_URL_PREFIX = '/api';
    private static readonly LS_BOOTSTRAP_KEY = 'heroapi';

    private readonly BOOTSTRAP_CONFIG_URL: string = 'assets/config.json';

    static getUrl(key: ServiceOperations) {
        let suffix: string;

        if (Constants.serviceDictionary[key] === AppConfig.AUTH_SRV_URL) {
            suffix = Constants.serviceDictionary[key];
        } else {
            suffix = AppConfig.COMMON_URL_PREFIX + Constants.serviceDictionary[key];
        }

        if (suffix !== undefined) {
            const lsHeroApiPath = sessionStorage.getItem(AppConfig.LS_BOOTSTRAP_KEY);
            if (lsHeroApiPath == null) {
                sessionStorage.setItem(this.LS_BOOTSTRAP_KEY, AppConfig.data.heroapi);
                return AppConfig.data.heroapi + suffix;
            } else {
                return lsHeroApiPath + suffix;
            }
        }
        return '';
    }

    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    load(): Promise<any> {

        return this.httpClient
            .get(this.BOOTSTRAP_CONFIG_URL)
            .toPromise()
            .then((data: any) => AppConfig.data = data)
            .catch((err: any) => Promise.resolve());
    }
}

export class Config {
    constructor(
        public title: string,
        public heroapi: string,
        public releaseNumber: string,
        public UseEnhancedSearch: boolean,
        public UseSearchAreaDistanceControl: boolean,
        public isProdBuild: boolean,
        public Countries: string[],
        public County: string[],
        public Nationalities: string[],
        public Sectors: string[],
        public Skills: string[],
        public CommunicationMethods: string[],
        public CommunicationCategories: string[],
        public ProximityDistance: number[],
        public DefaultDistance: number,
        public Tenant: string,
        public UseCoverLetterTextField: boolean,
        public ValidateRefereesOnProfileCompleteness: boolean,
        public displayCriminalConvictionsOnApply: boolean
    ) { }
}
