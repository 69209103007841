import { Component, Input, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';

import { Candidate } from '../../../../models/candidate.model';
import { FormSettingsHelperAbstractComponent } from '../form-settings-helper.abstract.component';
import { FormSettings } from '../../../../models/optionalFieldSettings.model';
import { Dictionary } from '../../../../helpers/Dictionary';
import { CandidateFields } from '../../../../datatypes/fields/candidate-fields.constants';
import { CommonFields } from '../../../../datatypes/fields/common-fields.constants';

@Component({
    selector: 'personal-details',
    templateUrl: './personal-details.component.html'
})

export class PersonalDetailsComponent extends FormSettingsHelperAbstractComponent {

    @ViewChildren(NgModel) controls: QueryList<NgModel>;

    @Input()
    public model: Candidate;

    @Input()
    public formSettings: FormSettings;

    @Input()
    public submitted: boolean;

    @Output()
    public onComponentChange = new EventEmitter<boolean>();

    public isValidTitle: boolean;
    public isValidFirstName: boolean;
    public isValidMiddleName: boolean;
    public isValidLastName: boolean;
    public isValidPhone: boolean;

    public titles: string[] = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof'];

    constructor(protected parentForm: NgForm) {
        super();
    }

    public ngOnInit(): void {
        this.validateTitle();
        this.validateFirstName();
        this.validateMiddleName();
        this.validateLastName();
    }

    ngAfterViewInit() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.addControl(control);
        });
    }

    public isComponentValid() {
        let validators = new Dictionary([
            { key: 'Title', value: this.isValidTitle },
            { key: 'First Name', value: this.isValidFirstName },
            { key: 'Middle Name', value: this.isValidMiddleName },
            { key: 'Last Name', value: this.isValidLastName },
            { key: 'Phone', value: this.isValidPhone }
        ]);

        for (let key in validators) {
            if (validators[key] !== undefined) {
                if (!validators[key]) {
                    this.onComponentChange.emit(false);
                    return;
                }
            }
        }
        this.onComponentChange.emit(true);
    }

    public validateTitle(): boolean {
        this.isValidTitle = !!this.model.Title || !this.isRequired(CandidateFields.title);
        this.isComponentValid();
        return this.isValidTitle;
    }

    public validateFirstName(): boolean {
        this.isValidFirstName = !!this.model.FirstName || !this.isRequired(CandidateFields.firstName);
        this.isComponentValid();
        return this.isValidFirstName;
    }

    public validateMiddleName(): boolean {
        this.isValidMiddleName = !!this.model.MiddleName || !this.isRequired(CandidateFields.middleName);
        this.isComponentValid();
        return this.isValidMiddleName;
    }

    public validateLastName(): boolean {
        this.isValidLastName = !!this.model.LastName || !this.isRequired(CandidateFields.lastName);
        this.isComponentValid();
        return this.isValidLastName;
    }

    onPhoneValidationChange(value: boolean) {
        this.isValidPhone = value;
    }
}