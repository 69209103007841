import { Injectable } from '@angular/core';

import { AbstractService } from 'src/app/services/abstract.service';
import { HttpClientHelper } from 'src/app/helpers/HttpClientHelper';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/helpers/helpers.service';

@Injectable({providedIn: 'root'})
export class SkeletonUnsubscribeService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService) {
        super(httpClientHelper, authenticationService, helper);
    }

    oneClickUnsubscribe(token: string) {
        return this.httpGetWithParamsObs('JobAlert.OneClickUnsubscribe', [`?token=${token}`]);
    }
}
