import { Observable } from 'rxjs';

import { Constants } from '../datatypes/constants';
import { ServiceReturn, KeyValue } from '../datatypes/misc';
import { ServiceOperations } from '../datatypes/service-operations';
import { HttpHelperInitiliseParams } from '../datatypes/misc';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { HttpClientHelper } from '../helpers/HttpClientHelper';

export abstract class AbstractService {

    protected constants: Constants;

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService) {
        this.constants = new Constants();
    }

    protected httpGetWithoutParams<T>(operation: ServiceOperations): Promise<ServiceReturn<T>> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], operation);

        return this.httpClientHelper.httpGet(httpHelperParams)
            .then(response => response as ServiceReturn<T>)
            .catch(this.helper.handleErrorPromise);
    }

    protected httpGetWithoutParamsObs<T>(operation: ServiceOperations): Observable<ServiceReturn<T>> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], operation);

        return this.httpClientHelper.httpGetObservable(httpHelperParams);
    }

    protected httpGetWithParams<T>(operation: ServiceOperations, params: string[]): Promise<T> {
        const httpHelperParams = new HttpHelperInitiliseParams(params, [], operation);

        return this.httpClientHelper.httpGet(httpHelperParams)
            .then(response => response as T)
            .catch(this.helper.handleErrorPromise);
    }

    protected httpDeleteWithParams<T>(operation: ServiceOperations, params: string[]): Promise<T> {
        const httpHelperParams = new HttpHelperInitiliseParams(params, [], operation);

        return this.httpClientHelper.httpDelete(httpHelperParams)
            .then(response => response as T)
            .catch(this.helper.handleErrorPromise);
    }

    protected httpGetWithParamsObs(operation: ServiceOperations, params: string[]): Observable<any> {
        const httpHelperParams = new HttpHelperInitiliseParams(params, [], operation, 'application/octet-stream');

        return this.httpClientHelper.httpGetObservable(httpHelperParams);
    }

    protected httpGetWithQueryParamsObs(operation: ServiceOperations, queryParams: KeyValue[]): Observable<any> {
        const httpHelperParams = new HttpHelperInitiliseParams([], queryParams, operation);

        return this.httpClientHelper.httpGetObservable(httpHelperParams);
    }

    protected httpPut<T>(operation: ServiceOperations, body: T): Promise<T> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], operation, body);

        return this.httpClientHelper.httpPut(httpHelperParams)
            .then(response => response as T)
            .catch(this.helper.handleErrorPromise);
    }

    protected putObs<T>(operation: ServiceOperations, theBody: T): Observable<T> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], operation, theBody);

        return this.httpClientHelper.httpPutObservable(httpHelperParams);
    }
}
