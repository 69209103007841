import { Subject } from 'rxjs';

import { ServiceOperations } from './service-operations';

export type ServiceReturn<T> = T | any | any[];

export interface MarshalledFormData<T1, T2> { name: T1; value: T2; }

export interface KeyValuePair extends Array<string | string> { 0: string; 1: string; }

export interface LabelMetaInfo extends Array<string | boolean> { 0: string; 1: boolean; }

export interface APIEnum extends Array<number | string> { id: number; name: string; }

export interface SelectorOption { value: string; label: string; }

export interface GenericEnumeration {
    id: number;
    name: string;
}

export type StringOrNumber = string | number;

export interface KeyValue { key: string; value: string; }

export enum ColumnLayout { One = 1, Two = 2 }

export type Nullable<T> = T | null;

export type BooleanSubject = Subject<boolean>;

// Constants Groups - MUST be kept aligned with Db

export enum EConstantsGroups {
    HOURS = 1,
    JOB_TYPE = 2,
    APPLICATION_TYPE = 3,
    SOURCE = 4,
    OPPORTUNITIES = 5,
    AVAILABILITY_TO_WORK = 6,
    DEFAULT_COLLEGE = 7,
    ADVERT_STATUS = 8
}

export enum ESearchPagination {
    PreviousPage = 9001,
    NextPage = 9002
}

export enum EAdvancedSearchStatus {
    ServiceUnavailable = 9001
}

export enum EAdvertSortOrder {
    PublishDate = 4001,
    GeoDistance = 4002,
    Score = 4003,
    ClosingDate = 4004,
}


export class Constant {
    Value: number;
    Name: string;
}

// an instance of this object will be created via the google location search/PlaceResultHelper.
export interface ILocationData {
    Coordinates: ICoordinates;
    DistanceInMiles: number;
    // DistanceInKm: number;
    PlaceResultMetaData: IPlaceResultMetaData;
    LocationName: string;
    // setCoordinates(lat: number, lng: number) : void;
}

export interface ILocationPlaceData extends ILocationData {
  PlaceResult: Nullable<google.maps.places.PlaceResult>;
}

export interface ICoordinates {
    Lat: number;
    Lng: number;
}

export interface IPlaceResultMetaData {
    InitialPlaceType: Nullable<string>;
    BoundingBoxDiagonalDistanceInKm: number;
    PseudoGeoSpatialDistanceInKm: number;
    IsPostalTown: Nullable<boolean>;
    IsAdminAreaLevel2: Nullable<boolean>;
    IsLargeConurbation: Nullable<boolean>;
    IsValidLocation: boolean;
}

export class LocationData implements ILocationData {

    Coordinates: ICoordinates;
    DistanceInMiles: number;
    // DistanceInKm: number;
    PlaceResultMetaData: IPlaceResultMetaData;
    LocationName: string;

    constructor() {
        this.Coordinates = { Lat: 0, Lng: 0 };
        this.DistanceInMiles = 0;
        // this.DistanceInKm = 0;
        // tslint:disable-next-line: no-use-before-declare
        this.PlaceResultMetaData = new PlaceResultMetaData();
        this.LocationName = '';
    }
}


// subtype also contains the google.maps.places.PlaceResult instance returned by google autocomplete
// this will be passed into the search control if the user performs a search on the landing page.
export class LocationPlaceData extends LocationData implements ILocationPlaceData {
  PlaceResult: Nullable<google.maps.places.PlaceResult>;
  constructor() {
    super();
    this.PlaceResult = null;
  }
}

export class PlaceResultMetaData implements IPlaceResultMetaData {

    public InitialPlaceType: Nullable<string>;
    public BoundingBoxDiagonalDistanceInKm: number;
    public PseudoGeoSpatialDistanceInKm: number; // this is half the value of boundingBoxDiagonalDistanceInKm
    public IsPostalTown: Nullable<boolean>;
    public IsPostalCode: Nullable<boolean>;
    public IsAdminAreaLevel2: Nullable<boolean>;
    public IsLargeConurbation: Nullable<boolean>;

    public IsValidLocation: boolean;

    public constructor() {
        this.InitialPlaceType = null;
        this.BoundingBoxDiagonalDistanceInKm = 0;
        this.PseudoGeoSpatialDistanceInKm = 0;
        this.IsPostalTown = null;
        this.IsPostalCode = null;
        this.IsAdminAreaLevel2 = null;
        this.IsLargeConurbation = null;
        this.IsValidLocation = false;
    }
}

export class HttpHelperInitiliseParams {

    public constructor(
        public optionalUrlParams: string[],
        public optionalQueryParams: KeyValue[],
        public urlKey: ServiceOperations,
        public body?: any,
        public headerContentType?: string,
        public options?: Object) { }
}
