import { ECategoryCriteria } from '../enums/ECategoryCriteriaEnum';

export class JobAlertCriteria {
    Title: string;
    Category: ECategoryCriteria; // This needs to be updated with the enum
    Uri: string;
    CategoryName: string;

    constructor(title: string, category: ECategoryCriteria, uri: string) {
        this.Title = title,
        this.Category = category,
        this.Uri = uri;
    }
}
