import { Injectable } from "@angular/core";

@Injectable()
export class CandidateFields {

    public static readonly title = "CANDIDATE_TITLE";
    public static readonly firstName = "CANDIDATE_FIRST_NAME";
    public static readonly middleName = "CANDIDATE_MIDDLE_NAME";
    public static readonly lastName = "CANDIDATE_LAST_NAME";
    public static readonly email = "CANDIDATE_EMAIL";
    public static readonly skills = "CANDIDATE_SKILLS";
    public static readonly currentlyStudying = "CANDIDATE_CURRENTLY_STUDYING";
    public static readonly collegeLocation = "CANDIDATE_COLLEGE_LOCATION";
    public static readonly course = "CANDIDATE_COURSE";
    public static readonly nationality = "CANDIDATE_NATIONALITY";
    public static readonly birthDate = "CANDIDATE_BIRTH_DATE";
    public static readonly drivingLicense = "CANDIDATE_DRIVING_LICENSE";
    public static readonly currentEmployer = "CANDIDATE_CURRENT_EMPLOYER";
    public static readonly currentJobTitle = "CANDIDATE_CURRENT_JOB_TITLE";
    public static readonly noticePeriod = "CANDIDATE_NOTICE_PERIOD";
    public static readonly contractType = "CANDIDATE_CONTRACT_TYPE";
    public static readonly hours = "CANDIDATE_HOURS";
    public static readonly availability = "CANDIDATE_AVAILABILITY_TO_START_WORK";
    public static readonly sectorOfInterest = "CANDIDATE_SECTOR_OF_INTEREST";
    public static readonly salaryExpectation = "CANDIDATE_SALARY_EXPECTATION";
    public static readonly criminalConvictions = "CANDIDATE_CRIMINAL_CONVICTIONS";
    public static readonly dateOfAcceptance = "CANDIDATE_ACCEPTANCE_DATE";
    public static readonly startDate = "CANDIDATE_START_DATE";
    public static readonly endDate = "CANDIDATE_END_DATE";
    public static readonly salaryExpectations = "CANDIDATE_SALARY_EXPECTATION";
    public static readonly qualifications = 'CANDIDATE_QUALIFICATIONS';

}