// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.form-group {
    position: relative;
}

span {
    position: absolute;
    top: 7px;
    right:0px;
}
    span i:active, span i:visited {
        color: #ccc;
    }

.form-control {
    margin-top: 0px;
}

@media only screen and (width: 768px) {
    span {
        position: absolute;
        top: 2px;
        right:0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/components/search/location/locationsearch.component.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;IACI;QACI,WAAW;IACf;;AAEJ;IACI,eAAe;AACnB;;AAEA;IACI;QACI,kBAAkB;QAClB,QAAQ;QACR,SAAS;IACb;AACJ","sourcesContent":["\r\n.form-group {\r\n    position: relative;\r\n}\r\n\r\nspan {\r\n    position: absolute;\r\n    top: 7px;\r\n    right:0px;\r\n}\r\n    span i:active, span i:visited {\r\n        color: #ccc;\r\n    }\r\n\r\n.form-control {\r\n    margin-top: 0px;\r\n}\r\n\r\n@media only screen and (width: 768px) {\r\n    span {\r\n        position: absolute;\r\n        top: 2px;\r\n        right:0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
