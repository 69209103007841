import { Component, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { Candidate } from '../../models/candidate.model';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConfig } from '../../app.config';
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'deleteaccount',
    templateUrl: './deleteaccount.component.html',
    styleUrls: ['./deleteaccount.component.css']
})
export class DeleteAccountComponent {

    @Input()
    public Candidate: Candidate;

    @Input()
    public modalId: string;

    @ViewChild('closeModalBtn', { static: false })
    public closeModalBtn: ElementRef;

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService,
        private accountService: AccountService,
        private router: Router,
        private toastr: ToastrService) {
    }

    deleteAccount() {
        this.accountService.deleteAccount()
            .then(isDeleted => {
                if (isDeleted) {
                    this.toastr.success("Done, account deleted!", "Success!");
                    this.router.navigate(['/account/login']);
                } else {
                    this.toastr.error("Account Deletion failed, please try again.");
                }
            })
            .catch(err => {
                this.toastr.error("Account Deletion failed, please try again.");
            });
    }

}
