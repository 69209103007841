import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CorporateConfig } from 'src/app/corporate.config';
import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'infohub',
    templateUrl: './infohub.component.html',
    styleUrls: ['./infohub.component.css']
})
export class InfohubComponent implements OnInit {

    displayOurServicePageTitle: boolean;
    infoHubPageContent = '';

    constructor(
        private helpersService: HelpersService,
        private title: Title
    ) {
        this.title.setTitle(this.helpersService.getPageContent(CorporateConfig.infoHubTitle));
    }

    ngOnInit() {
        this.infoHubPageContent = this.helpersService.getInfoHubPageContent();
        this.displayOurServicePageTitle = this.helpersService.getPageContent(CorporateConfig.displayOurServicePageTitle)?.toLowerCase() === 'true' ? true : false;
    }
}
