import { Component, Input } from '@angular/core';

@Component({
    selector: 'salary',
    templateUrl: './salary.component.html'
})
export class SalaryComponent {

    @Input()
    public salaryFromValue: number;

    @Input()
    public salaryToValue: number;

    @Input()
    public salaryComment: string;

    salaryToFrom(): boolean {
        if (!this.isSalaryFromNull() && !this.isSalaryToNull() && this.salaryFromValue !== this.salaryToValue) {
            return true;
        }
        return false;
    }

    salaryFixed(): boolean {
        if (!this.isSalaryFromNull() && this.salaryFromValue === this.salaryToValue) {
            return true;
        }
        return false;
    }

    salaryFrom(): boolean {
        if (!this.isSalaryFromNull() && this.isSalaryToNull()) {
            return true;
        }
        return false;
    }

    salaryUpTo(): boolean {
        if (this.isSalaryFromNull() && !this.isSalaryToNull()) {
            return true;
        }
        return false;
    }

    salaryHourly(): boolean {
        if (this.isSalaryFromNull() && this.isSalaryToNull() && this.salaryComment !== '') {
            return true;
        }
        return false;
    }

    salaryNA(): boolean {
        if (this.isSalaryFromNull() && this.isSalaryToNull() && this.salaryComment === '') {
            return true;
        }
        return false;
    }

    private isSalaryFromNull(): boolean {
        return this.salaryFromValue === null || this.salaryFromValue === 0;
    }

    private isSalaryToNull(): boolean {
        return this.salaryToValue === null || this.salaryToValue === 0;
    }
}
