import { Directive, HostListener } from '@angular/core';
import {  AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: "[TrimInput-Directive]"
})
export class TrimInputDirective {
  private ctrl: AbstractControl;
  
  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.ctrl = this.ngControl.control;
  }

  trimValue(value: string) : string {
    return value.trim();
  }

  @HostListener("blur", ["$event.target.value"])
  ngModelChange(value: any) {
    // only trim value if the model is dirty, this avoids 
    // setting the model as dirty if the user has not made any 
    // changes, i.e. they have just clicked on the field and then moved away
    if (this.ctrl.dirty) {
      const trimedValue = this.trimValue(value);
      if (value !== trimedValue) {
        this.ctrl.setValue(trimedValue)
      }
    } 
  }
}