import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CorporateConfig } from 'src/app/corporate.config';

import { HelpersService } from '../../../helpers/helpers.service';

@Component({
    selector: 'terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

    public termsOfUsePageContent = '';

    constructor(
        private helpersService: HelpersService,
        private metaService: Meta,
        private titleService: Title
    ) { 
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.termsOfUseTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.termsOfUseDescription});
    }

    ngOnInit() {
        this.termsOfUsePageContent = this.helpersService.getTermsOfUsePageContent();
    }
}
