import { Component, OnInit } from '@angular/core';

import { HelpersService } from '../../../helpers/helpers.service';

@Component({
    selector: 'freedom-of-information-act',
    templateUrl: './freedom-of-information-act.component.html',
    styleUrls: ['freedom-of-information-act.component.css']
})
export class FreedomOfInformationActComponent implements OnInit {

    public freedomOfInformationActPageContent = '';

    constructor(private helpersService: HelpersService) { }

    ngOnInit() {
        this.freedomOfInformationActPageContent = this.helpersService.getFreedomOfInformationActPageContent();
    }
}
