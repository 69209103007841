import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { JobAlertService } from '../../account/manage/job-alerts/services/job-alert.service';
import { JobAlert, JobAlertCriteria } from '../../account/manage/job-alerts/models';
import { EJobAlertStatusEnum } from '../../account/manage/job-alerts/enums/EJobAlertStatusEnum';
import { JbeModalTypes } from './enums/jbe-modal-types.enum';
import { CandidateService } from 'src/app/services/candidate.service';
import { SkeletonJobAlert, JobAlertAccountDto } from './models';
import { CandidateType } from './enums';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppConfig } from 'src/app/app.config';
import { JbeCustomModalService } from './services/custom-jbe-modal.service';
import { SearchFormSharedService } from '../searchForm/searchForm.shared.service';
import { ValidationConstants } from 'src/app/datatypes/validation.constants';
import { TenantService } from '../../../services/tenant.service';

@Component({
    selector: 'app-jbe-custom-modal',
    templateUrl: './jbe-custom-modal.component.html',
    styleUrls: ['./jbe-custom-modal.component.css']
})
export class JbeCustomModalComponent implements OnInit {

    @Input() isCreateJbeModal: boolean;
    @Input() isSkeletonCreateJbeModal: boolean;
    @Input() isSkeletonConfirmModal: boolean;
    @Input() isResendVerificationEmailModal: boolean;
    @Input() isCreateAccountModal: boolean;
    @Input() customModal: boolean;
    @Input() modalId: string;
    @Input() title: string;
    @Input() text: string;
    @Input() buttonText: string;
    @Input() data: JobAlertCriteria[];

    loading = false;
    error = '';
    form = new UntypedFormGroup({});
    tenantName: string = '';

    @Output() openNextModal = new EventEmitter<JbeModalTypes>();

    @ViewChild('closeModalBtn', { static: false }) closeModalBtn: ElementRef;

    constructor(
        private router: Router,
        private jobAlertService: JobAlertService,
        private fb: UntypedFormBuilder,
        private candidateService: CandidateService,
        private authService: AuthenticationService,
        private jbeCustomModalService: JbeCustomModalService,
        private searchFormSharedService: SearchFormSharedService,
        private tenantService: TenantService) { }

    ngOnInit() {
        if (this.isSkeletonCreateJbeModal) {
            this.form = this.fb.group({
                email: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidationConstants.emailRegex)]),
                newsAndEvents: new UntypedFormControl(false),
                careersAdvice: new UntypedFormControl(false)
            });
        }

        this.tenantService.getTenantDetails()
            .subscribe(tenant => {
                this.tenantName = tenant.TenantDisplayName;
            });

    }

    closeModal(): void {
        if (this.isSkeletonCreateJbeModal) {
            this.form.reset({ email: null, newsAndEvents: false, careersAdvice: false });
        }

        this.loading = false;
        this.error = '';
        this.closeModalBtn.nativeElement.click();
    }

    navigateToManageJobAlerts() {
        this.closeModal();
        this.router.navigate(['account/manage'], { fragment: '6' });
    }

    createJobAlert() {
        this.loading = true;
        const jobAlert = new JobAlert('', this.data, EJobAlertStatusEnum.Enabled, this.searchFormSharedService.getSortOrderValue());

        this.jobAlertService.create(jobAlert)
            .pipe(finalize(() => this.loading = false))
            .subscribe(res => {
                this.closeModal();
                this.openNextModal.emit(JbeModalTypes.Confirmation);
            });
    }

    createSkeletonJobAlert() {
        this.loading = true;
        const dto = this.form.value as SkeletonJobAlert;
        dto.advertSortOrder = this.searchFormSharedService.getSortOrderValue();
        dto.criteria = this.data;

        this.getAccountTypeWithCountAndCreateJobAlert(dto);

        this.loading = false;
    }

    navigateToRegistration() {
        this.closeModal();
        this.router.navigate(['account/register'],
            {
                queryParams: {
                    returnUrl: this.router.routerState.snapshot.url,
                    email: this.jbeCustomModalService.email.getValue(),
                    createJobAlert: true
                }
            });
    }

    private getAccountTypeWithCountAndCreateJobAlert(dto: SkeletonJobAlert) {
        this.candidateService.doesJbeAccountExist(dto.email)
            .subscribe(res => {
                this.jbeCustomModalService.setSubjects(this.form.controls['email'].value, dto, res);
                this.openCorrectJobAlertModal(res, dto);
            }, err => {
                this.error = err.error.Message;
            });
    }

    private openCorrectJobAlertModal(account: JobAlertAccountDto, dto: SkeletonJobAlert) {
        if (account) {

            const skeletonAccount = account.Exists && account.Type === CandidateType.Skeleton;
            const full = account.Exists && account.Type === CandidateType.Full;
            const pending = account.Exists && account.Type === CandidateType.Pending;
            const skeletonPending = account.Exists && account.Type === CandidateType.SkeletonPending;

            if (!account.Exists || (skeletonPending && account.Count === 0)) {
                this.createSkeletonJobAlertForSkeletonCandidate(dto);
            } else if (skeletonAccount || skeletonPending && account.Count > 0) {
                this.closeModal();
                this.openNextModal.emit(JbeModalTypes.CreateAccount);
            } else if (full && account.Count < 5) {
                this.createSkeletonJobAlertForCandidate(account, dto);
            } else if (full && account.Count === 5) {
                this.closeModal();
                this.openNextModal.emit(JbeModalTypes.MaxReached);
            } else if (pending) {
                this.authService.resendVerificationEmailAndCreateJobAlert(dto, account.UserId, dto.email)
                .then(() => {
                    this.closeModal();
                    this.openNextModal.emit(JbeModalTypes.ResendVerificationEmail);
                });
            }
        }
    }

    private createSkeletonJobAlertForSkeletonCandidate(dto: SkeletonJobAlert) {
        dto.createAccount = true;

        this.jobAlertService.createSkeletonJobAlert(dto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(res => {
                this.closeModal();
                this.openNextModal.emit(JbeModalTypes.SkeletonConfirm);
            });
    }

    private createSkeletonJobAlertForCandidate(account: JobAlertAccountDto, dto: SkeletonJobAlert) {
        dto.createAccount = false;
        dto.candidateId = account.CandidateId;

        this.jobAlertService.createSkeletonJobAlert(dto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(res => {
                this.closeModal();
                this.openNextModal.emit(JbeModalTypes.SkeletonConfirm);
            });
    }
}
