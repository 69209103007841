import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CorporateConfig } from 'src/app/corporate.config';
import { HelpersService } from 'src/app/helpers/helpers.service';
import { Applicant } from '../../models/applicant.model';
import { AccountService } from '../../services/account.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
    selector: 'applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

    public applicantList: Applicant[] = [];

    public isUserLoggedIn = this.localStorageService.isUserStillActive();
    public hasApplications = false;
    public applicationsLoaded = false;

    constructor(
        private accountService: AccountService,
        private localStorageService: LocalStorageService,
        private title: Title,
        private helperService: HelpersService
    ) { 
        this.title.setTitle(this.helperService.getPageContent(CorporateConfig.applicationsTitle));
    }

    ngOnInit(): void {
        if (this.isUserLoggedIn) {
            this.accountService.applicationsMade().then(res => this.setApplicants(res));
        }
    }

    downloadCv(guid: string) {
        this.accountService.downloadDocumentByGuid(guid, 'Candidate.CvDownload');
    }

    downloadAF(guid: string): void {
        this.accountService.downloadDocumentByGuid(guid, 'DownloadCandidateApplicationForm');
    }

    downloadCoverLetter(guid: string): void {
        this.accountService.downloadDocumentByGuid(guid, 'Candidate.CoverLetterDownload');
    }

    private setApplicants(applicants: Applicant[]): void {
        this.applicantList = applicants;
        this.hasApplications = (this.applicantList != null && this.applicantList.length > 0);
        this.applicationsLoaded = true;
    }
}
