import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  HostListener,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { LabelHelper } from 'src/app/helpers/label.helper';
import { CommonFields } from 'src/app/datatypes/fields/common-fields.constants';
import { PhoneNumbers } from 'src/app/models/phone-numbers.model';
import { Constants } from 'src/app/datatypes/constants';

@Component({
  selector: 'phone-numpers-app',
  templateUrl: './phone-numbers.component.html',
  styleUrls: ['./phone-numbers.component.css'],
})
export class PhoneNumbersComponent implements OnInit {
  @ViewChild('phoneFieldsForm') form: NgForm;

  @Input() isPhoneNumberRequired: boolean = false;
  @Input() numberList: PhoneNumbers[];
  @Input() submitted: boolean = false;
  @Output() onValidationChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  get phoneLabel() {
    return (
      LabelHelper.getLabel(CommonFields.phone) +
      (this.isPhoneNumberRequired ? '*' : '')
    );
  }
  dropdownInputDefaultSelect: string = Constants.dropdownInputDefaultSelect;
  types: string[] = Constants.DefaultPhoneTypes;
  allowMultipleInputs = true;

  isMobileView: boolean;
  hasValidPhoneNumber: boolean = false;

  private mobileBreakPoint = Constants.MobileScreenBreakingPoint;
  arrayIndex = 0; // the (arrayIndex + index)  approach is necessary because reusing an index will cause rendering problems

  ngOnInit() {
    this.validatePhones();
    this.isMobileView = window.innerWidth < this.mobileBreakPoint;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileView = window.innerWidth < this.mobileBreakPoint;
  }

  onSelectPreferred(index: number) {
    for (let i = 0; i < this.numberList.length; i++) {
      this.numberList[i].Preferred = (i === index);
    }
    this.validatePhones();
  }

  addNumber() {
    if (this.numberList.length < 4) {
      this.numberList.push({ Number: '', Type: '', Preferred: false });
      this.arrayIndex = new Date().getTime(); // used to generate unique identifier
      this.validatePhones();
    }
  }

  removeNumber(index: number) {
    if (this.numberList.length > 1) {
      // if the number to be deleted is the preferred number, set the first number to be preferred
      if (this.numberList[index].Preferred) {
        if (index === 0) {
          this.numberList[1].Preferred = true;
        }
        this.numberList[0].Preferred = true;
      }
      this.numberList.splice(index, 1);
      this.validatePhones();
    }
  }

  constraintDigit(event: any) {
    const pattern = /^[\+?0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  typeInputHasError(
    type: NgModel,
    number: NgModel,
    row: PhoneNumbers
  ): boolean {
    return (
      (type.invalid && type.touched && !this.hasValidPhoneNumber) ||
      (type.invalid && this.submitted && !this.hasValidPhoneNumber) ||
      (type.invalid && number.valid) ||
      (type.invalid && row.Preferred)
    );
  }

  numberInputHasError(
    number: NgModel,
    type: NgModel,
    row: PhoneNumbers
  ): boolean {
    return (
      (number.invalid && number.touched && !this.hasValidPhoneNumber) ||
      (number.invalid && this.submitted && !this.hasValidPhoneNumber) ||
      (number.invalid && type.valid) ||
      (number.invalid && row.Preferred)
    );
  }

  preferredInputHasError(
    number: NgModel,
    type: NgModel
  ): boolean {
    const noSelectedPreferred =
      this.numberList.filter((phone) => phone.Preferred)?.length === 0;
    return (
      (this.isPhoneNumberRequired && noSelectedPreferred && (type.touched || number.touched)) ||
      (this.isPhoneNumberRequired && noSelectedPreferred && this.submitted) ||
      (!this.isPhoneNumberRequired && noSelectedPreferred && !!this.numberList.filter(
        (phone) => phone.Type !== '' || phone.Number !== '')?.length)
    );
  }

  isTypeInputRequired(row: PhoneNumbers): boolean {
    return this.isPhoneNumberRequired || row.Number !== '' || row.Preferred;
  }

  isNumberInputRequired(row: PhoneNumbers): boolean {
    return this.isPhoneNumberRequired || row.Type !== '' || row.Preferred;
  }

  validatePhones() {
    this.hasValidPhoneNumber = !!this.numberList.filter(
      (phone) => phone.Type !== '' && phone.Number !== '' && phone.Preferred
    )?.length;
    for (let phone of this.numberList) {
      if (!this.arePhoneTypeAndNumberValid(phone)) {
        this.onValidationChange.emit(false);
        return;
      }
    }
    this.onValidationChange.emit(this.isPreferredNumberSelected());
  }

  arePhoneTypeAndNumberValid(phone: PhoneNumbers): boolean {
    if (this.isPhoneNumberRequired) {
      if (
        !this.hasValidPhoneNumber &&
        (phone.Type === '' || phone.Number === '')
      ) {
        return false;
      }
    } else {
      // valid if not required and all fields are empty while not required
      if (phone.Type === '' && phone.Number === '' && !phone.Preferred) {
        return true;
      }
    }
    if (
      (phone.Type !== '' && phone.Number === '') ||
      (phone.Type === '' && phone.Number !== '')
    ) {
      return false;
    }
    return true;
  }

  private isPreferredNumberSelected(): boolean {
    // valid when one preferred number is selected, and at least one row is entered
    if (this.hasValidPhoneNumber) {
      return true;
    } else {
      // check if any number selected as preferred
      const hasSelectedPreferredNumber =
        this.numberList.filter((phone) => phone.Preferred)?.length === 1;

      // if only one number added set it as preferrd
      if (this.numberList?.length === 1) {
        if (
          !hasSelectedPreferredNumber &&
          this.numberList[0].Type !== '' &&
          this.numberList[0].Number !== ''
        ) {
          this.numberList[0].Preferred = true;
          return true;
        }
        if (
          !this.isPhoneNumberRequired &&
          this.numberList[0].Type === '' &&
          this.numberList[0].Number === ''
        ) {
          this.numberList[0].Preferred = false;
          return true;
        }
      }

      if (
        !hasSelectedPreferredNumber &&
        !this.isPhoneNumberRequired &&
        !this.numberList.filter(
          (phone) => phone.Type !== '' || phone.Number !== ''
        )?.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
