import { CommonFields } from "../datatypes/fields/common-fields.constants";
import { CandidateFields } from "../datatypes/fields/candidate-fields.constants";
import { LabelMetaInfo } from "../datatypes/misc";
import { Dictionary, IDictionary } from "./Dictionary";
import { AppConfig } from "../app.config";
//import { CommonSevice } from "../services/common.service";


export class LabelHelper {

    private static _labelDictionary: IDictionary;

    public static mandatoryFieldSymbol = "*";

    public static initialize() {
        // /!\ ==================================
        // We added new Key/values in the dictionary for the new business specific fields feature
        // New keys are coming from constants, and values are the label to show on the form
        // The second value (boolean) is not used anymore as we get this value from the database (required field), 
        // So it is set to false by default to not break the dictionary
        // /!\ ==================================

        this._labelDictionary = new Dictionary([

            // candidate related


            { key: CandidateFields.title, value: ["Title", false] },
            { key: CandidateFields.firstName, value: ["First Name", false] },
            { key: CandidateFields.middleName, value: ["Middle Name(s)", false] },
            { key: CandidateFields.lastName, value: ["Last Name", false] },
            { key: CandidateFields.email, value: ["Email", false] },
            { key: CandidateFields.birthDate, value: ["Date of Birth", false] },
            { key: CandidateFields.skills, value: ["Skills", false] },
            { key: CandidateFields.qualifications, value: ["Qualifications", false] },
            { key: CandidateFields.nationality, value: ["Nationality", false] },
            { key: CandidateFields.currentlyStudying, value: ["Courses Studied", false] },
            { key: CandidateFields.collegeLocation, value: ["College Location", false] },
            { key: CandidateFields.course, value: ["Course", false] },
            { key: CandidateFields.startDate, value: ["Start Date", false] },
            { key: CandidateFields.endDate, value: ["End Date", false] },
            { key: CandidateFields.drivingLicense, value: ["Transport Details", false] },
            { key: CandidateFields.contractType, value: ["Contract Type", false] },
            { key: CandidateFields.hours, value: ["Hours", false] },
            { key: CandidateFields.availability, value: ["Availability to Start Work", false] },
            { key: CandidateFields.sectorOfInterest, value: ["Sector of Interest", false] },
            { key: CandidateFields.criminalConvictions, value: ["Convictions", false] },
            { key: CandidateFields.dateOfAcceptance, value: ["Date of Acceptance", false] },
            { key: CandidateFields.currentEmployer, value: ["Current Employer", false] },
            { key: CandidateFields.currentJobTitle, value: ["Current Job Title", false] },
            { key: CandidateFields.noticePeriod, value: ["Notice Period (Days)", false] },
            { key: CandidateFields.salaryExpectation, value: ["Salary Expectations", false] },

            // address related
            { key: "address.type", value: ["Address Type", true] },
            { key: "address.street1", value: ["1st line of address", true] },
            { key: "address.towncity", value: ["Town or City", true] },
            { key: "address.county", value: ["County", false] },
            { key: "address.country", value: ["Country", true] },
            { key: "address.postcode", value: ["Postcode", true] },


            // Common labels
            { key: CommonFields.phone, value: ["Phone", false] },
            { key: CommonFields.addressType, value: ["Address Type", false] },
            { key: CommonFields.firstLineAddress, value: ["1st line of address", false] },
            { key: CommonFields.secondLineAddress, value: ["2nd line of address", false] },
            { key: CommonFields.city, value: ["Town or City", false] },
            { key: CommonFields.postcode, value: ["Postcode", false] },
            { key: CommonFields.county, value: ["County", false] },
            { key: CommonFields.country, value: ["Country", false] },
        ]);

        // Add Communication keys
        let candidateComsPrefCategories = AppConfig.CommunicationCategories;
        for (let i = 0; i < candidateComsPrefCategories.length; i++) {
            this._labelDictionary.add(CommonFields.communication(i + 1), [candidateComsPrefCategories[i], false]);
        }
    }

    // TODO - need to handle situation where key is missing (!)
    public static isRequired(key: string): boolean {
        return (this.getLabelMetaInfo(key)[1]) as any;
    }

    public static getLabel(key: string): string {
        const value = this.getLabelMetaInfo(key);
        let label = value[0];
        if (value[1]) // 'required' attribute 
        {
            label = label + LabelHelper.mandatoryFieldSymbol;
        }
        return (label) as any;
    }

    private static getLabelMetaInfo(key: string): LabelMetaInfo {
        return this._labelDictionary[key];
    }

    public static getOptionalLabel(required: boolean, label: string): string {
        if (required) {
            return label;
        } else {
            return label.slice(0, -1);
        }
    }

    public static getKeyByValue(value: any): string | null {
        
        // Find the key by value
        const foundKey = Object.keys(this._labelDictionary).find(key => (
            key != null && this._labelDictionary[key] && this._labelDictionary[key][0] == value
        ) ? key : null);

        // Check if a key was found
        if (!foundKey) {
            console.error(`Key not found for value: ${value}`);
            return null;
        }
        return foundKey;
    }

      

}

