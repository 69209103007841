import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CorporateConfig } from 'src/app/corporate.config';

import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {

    aboutPageContent = '';
    displayAboutPageTitle: boolean;

    constructor(
        private helpersService: HelpersService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.aboutUsTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.aboutUsDescription});
    }

    ngOnInit() {
        this.aboutPageContent = this.helpersService.getAboutPageContent();
        this.displayAboutPageTitle = this.helpersService.getPageContent(CorporateConfig.displayAboutPageTitle)?.toLowerCase() === 'true' ? true : false;
    }
}
