import { Injectable } from "@angular/core";

@Injectable()
export class CommonFields {

    public static readonly addressType = "ADDRESS_TYPE";
    public static readonly firstLineAddress = "FIRST_LINE_ADDRESS";
    public static readonly secondLineAddress = "SECOND_LINE_ADDRESS";
    public static readonly city = "CITY";
    public static readonly postcode = "POSTCODE";
    public static readonly county = "COUNTY";
    public static readonly country = "COUNTRY";

    public static readonly phone = "PHONE";

    public static communication(id: number) {
        return "COMMUNICATION_" + id;
    }

}