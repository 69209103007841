import { Component, OnInit } from '@angular/core';

import { HelpersService } from '../../../helpers/helpers.service';

@Component({
    selector: 'modern-slavery-act',
    templateUrl: './modern-slavery-act.component.html'
})
export class ModernSlaveryActComponent implements OnInit {

    public modernSlaveryActPageContent = '';

    constructor(private helpersService: HelpersService) { }

    ngOnInit() {
        this.modernSlaveryActPageContent = this.helpersService.getModernSlaveryActPageContent();
    }
}
