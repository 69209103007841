import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';

import { AccountService } from '../../../../services/account.service';


@Component({
    selector: 'change-email-modal',
    templateUrl: './changeEmailModal.component.html',
    styles: ['.container { min-height: calc(100vh - 436px);} h1 { margin-top: 40px;}']
})
export class ChangeEmailModalComponent implements OnInit {

    @Input()
    public modalId: string;


    @ViewChild('closeModalBtn', { static: false })
    public closeModalBtn: ElementRef;

    public emailResent = true;

    titleText: string;
    bodyText: string;
    errorText: string;
    resendBtnText: string;
    cancelBtnText: string;

    constructor(private accountService: AccountService) { }

    ngOnInit() {
        this.initText();
    }

    initText() {
        this.titleText = 'Request to change email address';
        this.bodyText = 'You have requested to change your email address on this account. Please click on the link in the verification email sent to you when you requested the change to complete the process.';
        this.errorText = 'Failed to resend email, please try again later.';
        this.resendBtnText = 'Resend Verification Email';
        this.cancelBtnText = 'Cancel change email request';
    }


    resendVerificationEmail(): void {
        this.accountService.resendChangeEmailVerification()
            .then(result => {
                this.emailResent = result;
                if (this.emailResent) {
                    this.closeModalBtn.nativeElement.click();
                }
            })
            .catch(error => {
                this.emailResent = false;
            });
    }

    cancelRequest(): void {
        this.accountService.closeChangeEmailRequest();
        this.closeModalBtn.nativeElement.click();
    }
}
