// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weg-btn {
    width: 100%;
}

::ng-deep .mat-checkbox-layout {
    white-space: normal !important;
}

::ng-deep .mat-checkbox-label {
    font-size: 15px;
}

.mat-checkbox {
    margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/search/jbe-custom-modal/jbe-custom-modal.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".weg-btn {\r\n    width: 100%;\r\n}\r\n\r\n::ng-deep .mat-checkbox-layout {\r\n    white-space: normal !important;\r\n}\r\n\r\n::ng-deep .mat-checkbox-label {\r\n    font-size: 15px;\r\n}\r\n\r\n.mat-checkbox {\r\n    margin-bottom: 0.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
