import { Component, Input, Output, EventEmitter, ElementRef, SimpleChanges, OnChanges } from '@angular/core';

@Component({
    selector: 'multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.css']
})
export class MultiSelectComponent implements OnChanges {

    @Input() sortValues: boolean = false;
    @Input() values: string[];
    @Input() itemWithSpecificOrder: IItemWithSpecificOrder | null = null;

    @Input('chosenValues')
    set chosenValues(values: string[]) {
        this.chosen = values;
        if (this.chosen?.length > 0 && this.values) {
            this.values = this.values.filter(el => this.chosen.indexOf(el) < 0); // removing the chosen values from the skill list
        }
    }

    @Input() id: string = "";

    // tslint:disable-next-line: no-output-on-prefix
    @Output()
    public onComponentChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Input()
    public isRequired = false;

    @Input()
    public submited = false;

    public chosen: string[] = [];
    public dummy = 'select1';
    public dummyFlag = true;

    constructor(private element: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sortValues) {
            this.sortValues ? this.values = this.values.sort(): null;
        }
      }

    add(value: any) {
        this.values.splice(this.values.indexOf(value), 1);
        this.chosen.push(value);
        this.onComponentChange.emit(this.chosen);

        this.dummyFlag = !this.dummyFlag;
        this.valueSwitcher(this.dummyFlag);
    }

    remove(value: any) {
        this.values.push(value);
        this.chosen.splice(this.chosen.indexOf(value), 1);

        this.onComponentChange.emit(this.chosen);
        this.values.sort(this.stringCompare);
        !!this.itemWithSpecificOrder ? this.sortCustomValuesOrder() : null;
        if (this.chosen.length === 0) {
            const select: HTMLInputElement = this.element.nativeElement.querySelector('select[name=\'multiselect\']');
            select.focus();
            select.blur();
            select.focus();
        }
    }

    // trick to keep select value after chosing a value
    // the ngModel on the select is property binded using the dummy value
    // the selected value should always be "Please Select"
    // Because of removing the selected values from the array the selected values becomes empty, switching back to the same
    // select string won't trigger the change method. You have to select another value, thats why I use 2 values to switch between
    // them an triiger the angular change method
    private valueSwitcher(value: boolean) {
        if (value) {
            this.dummy = 'select1';
        } else {
            this.dummy = 'select2';
        }
    }

    private stringCompare(a: string, b: string): number {
        const nameA = a.toUpperCase(); // ignore upper and lowercase
        const nameB = b.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    }

    public isMultiselectInvalid(): boolean {
        if (this.isRequired) {
            return this.chosen.length === 0;
        }
        return false;
    }

    private sortCustomValuesOrder() {
        const index = this.values.indexOf(this.itemWithSpecificOrder.item);
        if (index !== -1) {
            this.values.splice(index, 1); // Remove the string from its current position
            this.itemWithSpecificOrder.position === "start" ? // place the string at the start or the end
            this.values.unshift(this.itemWithSpecificOrder.item) :
            this.values.push(this.itemWithSpecificOrder.item);
        }
    }
}


export interface IItemWithSpecificOrder {
    item: string;
    position: "start" | "end";
}