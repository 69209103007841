import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { tap, switchMap } from 'rxjs/operators';

import { SkeletonUnsubscribeService } from './services/skeleton-unsubscribe.service';

@Component({
  selector: 'app-unsubscribe-successful',
  templateUrl: './unsubscribe-successful.component.html',
  styleUrls: ['./unsubscribe-successful.component.css']
})
export class UnsubscribeSuccessfulComponent implements OnInit {

  website = window.location.host;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private unsubscribeService: SkeletonUnsubscribeService) { }

  ngOnInit() {
    this.route.params.pipe(
      tap(params => this.token = params.token),
      switchMap(() => this.unsubscribeService.oneClickUnsubscribe(this.token))
    ).subscribe(res => console.log('UNSUBSCRIBED'));
  }

}
