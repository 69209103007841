import { Component, OnInit } from '@angular/core';

import { CorporateConfig } from '../../corporate.config';
import { HelpersService } from '../../helpers/helpers.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
    selector: 'appfooter',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    footerCopyRight: string;
    footerCompanyDetails: string;
    twitterLink: string;
    facebookLink: string;
    linkedInLink: string;
    instagramLink: string;
    footerLinkTermsOfUse: string;
    footerLinkPrivacyPolicy: string;
    footerLinkCookiePolicy: string;
    footerLinkAccessibility: string;
    footerLinkModernSlaveryAct: string;
    footerLinkFreedomOfInformationAct: string;
    phoneNumber: string;
    email: string;
    todaysDate = new Date();
    
    constructor(private helpersService: HelpersService, private tenantService: TenantService) { }
    
    ngOnInit() {
        this.tenantService.getTenantDetails().subscribe(res => {
            this.phoneNumber = res.TenantContactNumber;
            this.email = res.TenantEmail;
        });

        this.footerCopyRight = this.helpersService.getFooterCopyRightText();
        this.footerCompanyDetails = this.helpersService.getPageContent(CorporateConfig.footerCompanyDetails);
        
        if ((this.footerCopyRight.includes('Uniperms') || this.footerCopyRight.includes('WEG Technology Recruitment')) && this.todaysDate.getFullYear() > 2023) {
            this.footerCopyRight = this.footerCopyRight + ` - ${this.todaysDate.getFullYear()}`;
        }

        this.twitterLink = this.helpersService.getPageContent(CorporateConfig.twitterLink);
        this.facebookLink = this.helpersService.getPageContent(CorporateConfig.facebookLink);
        this.linkedInLink = this.helpersService.getPageContent(CorporateConfig.linkedInLink);
        this.instagramLink = this.helpersService.getPageContent(CorporateConfig.instagramLink);
        
        this.footerLinkTermsOfUse = this.helpersService.getPageContent(CorporateConfig.footerLinkTermsOfUse);
        this.footerLinkPrivacyPolicy = this.helpersService.getPageContent(CorporateConfig.footerLinkPrivacyPolicy);
        this.footerLinkCookiePolicy = this.helpersService.getPageContent(CorporateConfig.footerLinkCookiePolicy);
        this.footerLinkAccessibility = this.helpersService.getPageContent(CorporateConfig.footerLinkAccessibility);
        this.footerLinkModernSlaveryAct = this.helpersService.getPageContent(CorporateConfig.footerLinkModernSlaveryAct);
        this.footerLinkFreedomOfInformationAct = this.helpersService.getPageContent(CorporateConfig.footerLinkFreedomOfInformationAct);
    }

}
