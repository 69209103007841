import { Directive, Input, ElementRef, HostListener, Renderer2, HostBinding } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

import { ValidationConstants } from '../../datatypes/validation.constants';

@Directive({
    selector: '[postcodeValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PostCodeValidatorDirective,
            multi: true
        }]
})
export class PostCodeValidatorDirective implements Validator {

    public postcodeValidation = ValidationConstants.ukPostcodeRegexValue;

    @Input()
    postcodeValidator: string;

    validate(control: AbstractControl): { [key: string]: any; } | null {
        const country = control.root.get(this.postcodeValidator);
        if (country && country.value !== 'United Kingdom') {
            return null;
        } else if (country && country.value === 'United Kingdom') {
            const result = RegExp(this.postcodeValidation).test(control.value);
            if (!result) {
                return { 'inValidPostcode': true };
            } else {
                return null;
            }
        }
        return null;
    }
}
