import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CorporateConfig } from 'src/app/corporate.config';

import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ClientsComponent implements OnInit {

    clientsPageContent: string;
    displayClientPageTitle: boolean;

    constructor(
        private helpersService: HelpersService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.clientTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.clientDescription});
    }

    ngOnInit() {
        this.clientsPageContent = this.helpersService.getClientsPageContent();
        this.displayClientPageTitle = this.helpersService.getPageContent(CorporateConfig.displayClientPageTitle)?.toLowerCase() === 'true' ? true : false;

    }
}
