// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rd-btn-y {
    margin-right: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
 `, "",{"version":3,"sources":["webpack://./src/app/components/account/manage/employment/employment.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA,gCAAgC;AAChC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,0BAA0B;AAC9B","sourcesContent":[".rd-btn-y {\r\n    margin-right: 8px;\r\n}\r\n\r\n/* Chrome, Safari, Edge, Opera */\r\ninput::-webkit-outer-spin-button,\r\ninput::-webkit-inner-spin-button {\r\n    -webkit-appearance: none;\r\n    margin: 0;\r\n}\r\n\r\n/* Firefox */\r\ninput[type=number] {\r\n    -moz-appearance: textfield;\r\n}\r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
