import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthGuard  {
        constructor(private router: Router, private localStorageService: LocalStorageService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.localStorageService.isUserStillActive()) {
            return true;
        }
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.localStorageService.isUserStillActive()) {
            return true;
        }
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
