import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from '../../services/candidate.service';
import { TenantService } from '../../services/tenant.service';
import { TenantDetails } from '../../models/tenantModel';
import { map, switchMap, switchMapTo } from 'rxjs/operators';


@Component({
    selector: 'email-consent',
    templateUrl: './email-consent.component.html',
    styleUrls: ['./email-consent.component.css']
})

export class EmailConsentComponent implements OnInit {

    tenantName: string = '';
    tenantNumber: string = '';

    consentConfirmSuccessful: boolean = null;



    constructor(
        private activatedRoute: ActivatedRoute,
        private candidateService: CandidateService,
        private tenantService: TenantService
    ){}

    ngOnInit() {

        this.tenantService.getTenantDetails()
            .pipe(
                map(tenant => {
                    this.tenantName = tenant.TenantDisplayName;
                    this.tenantNumber = tenant.TenantContactNumber;
                }),
                switchMapTo(this.activatedRoute.params),
                switchMap(params => this.candidateService.confirmEmailConsent(params.token))
            ).subscribe(res => {
                this.consentConfirmSuccessful = res;
            });
    }


}
