import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[equalFieldsValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ConfirmEqualValidatorDirective,
            multi: true
        }]
})
export class ConfirmEqualValidatorDirective implements Validator {

    @Input()
    equalFieldsValidator: string;

    validate(control: AbstractControl): { [key: string]: any; } | null {
        const controlToCompare = control.root.get(this.equalFieldsValidator);
        if (controlToCompare && controlToCompare.value !== control.value) {
            return { 'notEqual': true };
        }
        return null;
    }
}
