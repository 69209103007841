// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect-btn {
    border-radius: 60px;
    margin-bottom: 5px;
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/multiselect/multiselect.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".multiselect-btn {\r\n    border-radius: 60px;\r\n    margin-bottom: 5px;\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
