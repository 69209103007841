import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ChangePassword } from '../models/changePassword.model';
import { Candidate, CandidateTermsAndConditionsConsent } from '../models/candidate.model';
import { Applicant } from '../models/applicant.model';
import { ApplicantResponse } from '../models/request-response.model';
import { ServiceOperations } from '../datatypes/service-operations';
import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { Cv } from '../models/document.model';
import { HttpHelperInitiliseParams } from '../datatypes/misc';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { ChangeEmail } from '../models/changeEmail.model';
import { AppConfig } from '../app.config';

@Injectable()
export class AccountService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService,
        private httpClient: HttpClient
    ) {
        super(httpClientHelper, authenticationService, helper);
    }

    // post //////////////////////////////////////////////////////////////////////////////////////
    changePassword(changePassword: ChangePassword): Observable<any> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Password.Change', changePassword);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(
            map(this.helper.extractData1),
            catchError(this.helper.handleErrorObservable));
    }

    getCandidate(): Promise<Candidate> {
        return this.httpGetWithoutParams('Candidate.Fetch');
    }

    updateCandidate(candidate: Candidate): Promise<Candidate> {
        return this.httpPut('Candidate.Update', candidate);
    }

    updateCandidateConsent(candidateConsent: CandidateTermsAndConditionsConsent): Promise<CandidateTermsAndConditionsConsent> {
        return this.httpPut('Candidate.UpdateConsent', candidateConsent);
    }


    changeEmail(changeEmail: ChangeEmail): Observable<any> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Candidate.ChangeEmail', changeEmail);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(
            map(this.helper.extractData1),
            catchError(this.helper.handleErrorObservable));
    }

    resendChangeEmailVerification(): Promise<boolean> {
        return this.httpGetWithoutParams('Candidate.ResendChangeEmailVerification');
    }

    existsUnConfirmedEmail(): Promise<boolean> {
        return this.httpGetWithoutParams('Candidate.ExistsUnConfirmedEmail');
    }

    closeChangeEmailRequest(): Promise<boolean> {
        return this.httpGetWithoutParams('Candidate.CloseChangeEmailRequest');
    }

    applyForJob(formData: FormData): Observable<ApplicantResponse> {

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${this.authenticationService.token}`);
        const options = { headers: headers };

        return this.httpClient.put<ApplicantResponse>(AppConfig.getUrl('Advert.Apply'), formData, options)
            .pipe(catchError(
                err => this.helper.handleErrorObservable(err)
            ));
    }

    getUserUnConfirmedEmail(userId?: string): Observable<{Email: string}> {
        const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: myHeaders };
        let url = AppConfig.getUrl('Candidate.UnConfirmedEmail');

        if (userId != null) {
            url = url + `?userId=${userId}`
        }

        return this.httpClient.get<{Email: string}>(url, options);
    }

    getCvs(): Promise<Cv[]> {
        return this.httpGetWithoutParams<Cv[]>('Candidate.CvList');
    }

    downloadCvByHash(cvHash: string): void {
        this.getDocumentX(cvHash, 'Candidate.CvDownload');
    }

    getDocumentX(sha1: string, serviceOperation: ServiceOperations) {
        // Add observe of type response to get back the whole response so the blob can access details like file name, type ..
        const options = { headers: this.createHeaders('application/json'), observe: 'response', responseType: 'blob' };

        const httpHelperParams = new HttpHelperInitiliseParams([sha1], [], serviceOperation, null, '', options);

        return this.httpClientHelper.httpPutObservable(httpHelperParams)
            .subscribe(r => this.extract(r));
    }

    downloadDocumentByGuid(guid: string, serviceUrl: ServiceOperations) {

        const options = { headers: this.createHeaders('application/json'), observe: 'response', responseType: 'blob' };

        const httpHelperParams = new HttpHelperInitiliseParams([guid], [], serviceUrl, null, '', options);

        return this.httpClientHelper.httpPutObservable(httpHelperParams)
            .subscribe(r => this.extract(r));
    }

    cvUploadNotAllowed(): Promise<boolean> {
        return this.httpGetWithoutParams<boolean>('Candidate.CvUploadNotAllowed');
    }

    deleteAccount(): Promise<boolean> {
        return this.httpDeleteWithParams<any>("Account.Delete", []);
    }

    alreadyApplied(advertId: number): Promise<Applicant> {
        return this.httpGetWithParams('Candidate.AlreadyApplied', [`?advertId=${advertId}`]);
    }

    alreadyAppliedGuid(advertGuid: string): Promise<Applicant> {
        return this.httpGetWithParams('Candidate.AlreadyAppliedGuid', [`?advertGuid=${advertGuid}`]);
    }

    applicationsMade(): Promise<Applicant[]> {
        return this.httpGetWithoutParams<Applicant[]>('Candidate.Applications');
    }

    setPassword(dto: object) {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Password.Set', dto);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(
            catchError(this.helper.handleErrorObservable));
    }

    resendVerificationEmail(email: string) {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Account.ResendVerificationEmail', email);

        return this.httpClientHelper.httpPost(httpHelperParams);
    }

    private createHeaders(contentType?: string): HttpHeaders {
        let headers = new HttpHeaders();
        let contentTypeHdr = 'application/json';

        if (contentType !== undefined || contentType !== null) {
            contentTypeHdr = contentType;
        }

        headers = headers.set('Accept', 'application/octet-stream')
            .set('Content-Type', contentTypeHdr)
            .set('Authorization', `Bearer ${this.authenticationService.token}`);

        return headers;
    }

    private extract(res: any): void {
        const blob = new Blob([res.body], { type: res.body.type });
        const url = window.URL.createObjectURL(blob);
        const fileName = res.headers.get('X-FileName');

        //// IE11 & Edge
        //if (navigator.msSaveBlob) {
        //    navigator.msSaveBlob(blob, fileName);
        //} else {
            // Chrome and Firefox
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        //}
    }
}
