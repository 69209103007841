import { ESearchPagination, Nullable, LocationData, ILocationData, EAdvertSortOrder } from '../datatypes/misc';
import { AppConfig } from '../app.config';


export class PagingDictionary {

    public Dictionary: Map<string, string>;
    public PagingParameter: Nullable<ESearchPagination>;
    public MaxNumResultPages: number;
    public CurrentPage: number;
    public RequestedPage: number;

    public isFirstPage: boolean;
    public isLastPage: boolean;

    constructor() {
        this.isFirstPage = true;
        this.CurrentPage = 1;
        this.RequestedPage = 1;
    }
}

export class AdvertQuery {

    public AdvertSortOrder: EAdvertSortOrder;
    public PagingDictionary: PagingDictionary;
    // public PagingParameter: Nullable<ESearchPagination>;

    public KeywordParams: string;
    // public JobCategories: number[];  // JobCategoryId array
    // public SalaryFrom: number;
    // public SalaryTo: number;


    public LocationPlaceData: ILocationData;

    // public ContractType : number; // ContractType 'value' from constants table
    // public HoursType: number; // Hours 'value' from constants table

    constructor() {
        this.PagingDictionary = new PagingDictionary();
        // this.CursorContainer = new CursorContainer();
        this.LocationPlaceData = new LocationData();
        this.LocationPlaceData.DistanceInMiles = AppConfig.DefaultDistance;
        this.KeywordParams = '';

        // this.JobCategories = [];
        // this.SalaryFrom = 0;
        // this.SalaryTo = 0;
        // this.Location = null;
        // this.ContractType = 0;
        // this.HoursType = 0;
    }


    // public addJobCategory(jobCat: number) {
    //     if (jobCat && this.JobCategories.indexOf(jobCat) === -1) {
    //         this.JobCategories.push(jobCat);
    //     }
    // }

    // public removeJobCategory(jobCat: number) {
    //     if (jobCat) {
    //         const index = this.JobCategories.indexOf(jobCat);
    //         if (index > -1) {
    //             this.JobCategories.splice(index, 1);
    //         }
    //     }
    // }



}

export class SearchTerm {
    constructor(
        public value: any,
        public type: SearchTermTypes
    ) { }
}

export enum SearchTermTypes {
    KEYWORD = 1,
    LOCATION = 2,
    DISTANCE = 3
}
