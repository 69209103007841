// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wrapper {
    min-height: calc(100vh - 344px);
}


@media screen and (min-width: 1537px) {
    .wrapper {
        margin-bottom: 8px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/app/components/app/app.component.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;AACnC;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["\r\n.wrapper {\r\n    min-height: calc(100vh - 344px);\r\n}\r\n\r\n\r\n@media screen and (min-width: 1537px) {\r\n    .wrapper {\r\n        margin-bottom: 8px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
