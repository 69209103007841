import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SkeletonJobAlert, JobAlertAccountDto } from '../models';

@Injectable({ providedIn: 'root' })
export class JbeCustomModalService {

    email = new BehaviorSubject<string>(null);
    dto = new BehaviorSubject<SkeletonJobAlert>({} as SkeletonJobAlert);
    account = new BehaviorSubject<JobAlertAccountDto>({} as JobAlertAccountDto);

    setSubjects(email: string, dto: SkeletonJobAlert, account: JobAlertAccountDto) {
        this.email.next(email);
        this.account.next(account);
        this.dto.next(dto);
    }
}
