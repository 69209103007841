import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatRippleModule,
    MAT_DATE_FORMATS,
    DateAdapter,
    MatNativeDateModule,
    MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { CustomDateAdapter } from '../helpers/custom-date-adapter';
import { Constants } from '../datatypes/constants';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

const modules = [
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatTabsModule,
    MatMomentDateModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatIconModule
];

@NgModule({
    imports: [modules],
    exports: [modules],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        //{ provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: Constants.CUSTOM_DATE_FORMAT }
    ]
})

export class MaterialModule { }
