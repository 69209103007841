import { Injectable } from '@angular/core';

import { map, catchError } from 'rxjs/operators';

import { Advert, AdvertQueryResponse, AdvertEqualityQuestionsContainer } from '../models/advert.model';
import { AdvertQuery } from '../models/advertQuery.model';
import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { HttpHelperInitiliseParams, Nullable } from '../datatypes/misc';


@Injectable()
export class AdvertService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService) {
        super(httpClientHelper, authenticationService, helper);
    }

    getAdvertList(): Promise<Advert[]> {
        return this.httpGetWithoutParams('Advert.List');
    }

    getActiveAdvertList(): Promise<Advert[]> {
        return this.httpGetWithoutParams('Advert.ListActive');
    }

    getAdvert(id: number): Promise<Advert> {
        return this.httpGetWithParams<Advert>('Advert.Fetch', [`?advertId=${id}`]);
    }

    getAdvertEqualityQuestions(advertId: number): Promise<AdvertEqualityQuestionsContainer> {
        return this.httpGetWithParams<AdvertEqualityQuestionsContainer>('Advert.ListEqualityQuestions', [`?advertId=${advertId}`]);
    }

    isEqualityDataRecorded(advertId: number): Promise<boolean> {
        return this.httpGetWithParams<boolean>('Advert.IsEqualityDataRecorded', [`?advertId=${advertId}`]);
    }

    getAdvertByGuid(guid: string): Promise<Advert> {
        return this.httpGetWithParams<Advert>('Advert.FetchByGuid', [`?advertGuid=${guid}`]);
    }

    // public getAdvertSearch(model : AdvertQuery): Promise<AdvertSearchResult> {
    //     return this.httpPosttWithoutParams("Advert.FilteredSearch");
    //     //this.httpGetWithoutParams<AdvertSearchResult>("Advert.FilteredSearch");
    // }

    getAdvertSearch(advertQuery: AdvertQuery): Promise<AdvertQueryResponse> {
        const httpHelperParams = new HttpHelperInitiliseParams([], [], 'Advert.FilteredSearch', advertQuery);

        return this.httpClientHelper.httpPost(httpHelperParams).pipe(
            map(this.helper.extractData),
            catchError(this.helper.handleErrorObservable)).toPromise();
    }

    getAdvertPreview(guid: string): Promise<Advert> {
        return this.httpGetWithParams<Advert>('AdvertPreview.FetchByGuid', [`?advertGuid=${guid}`]);
    }

    CandidateDetailsSentOutsideUkEea(jobId: number): Promise<Nullable<boolean>> {
        return this.httpGetWithParams<Nullable<boolean>>('Advert.candidateDetailsSentOutsideUkEea', [`?jobId=${jobId}`]);
    }
}
