import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { CandidateSavedJob } from '../../../models/candidateSavedJob.model';
import { CandidateService } from '../../../services/candidate.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
    selector: 'savedJobs-indicator',
    templateUrl: './savedJobs-indicator.component.html',
    styleUrls: ['./savedJobs-indicator.component.css']
})
export class SavedJobsIndicatorComponent implements OnInit {

    // tslint:disable-next-line: no-input-rename
    @Input('jobIdOrGuid')
    jobIdOrGuid: string;

    public savedJobs: CandidateSavedJob[] = [];
    private isUserLoggedIn = this.localStorageService.isUserStillActive();
    public isJobSaved = false;

    constructor(
        public candidateService: CandidateService,
        private router: Router,
        private toastr: ToastrService,
        private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        if (this.isUserLoggedIn) {
            this.getSavedAdvertList();
        }
    }

    toggleJob(): void {
        if (this.isUserLoggedIn) {
            if (this.isJobSaved) {
                this.removeSavedJob(this.jobIdOrGuid);
            } else {
                this.saveJob(this.jobIdOrGuid);
            }
        } else {
            const storedObj = { path: this.router.url, jobId: this.jobIdOrGuid };
            sessionStorage.setItem('SavedJob', JSON.stringify(storedObj));
            this.router.navigate(['/account/login']);
            this.isJobSaved = true;
        }
    }

    private saveJob(jobIdOrGuid: string): void {
        this.candidateService.saveJob(jobIdOrGuid).subscribe(
            res => {
                if (res) {
                    this.toastr.info('Job saved to Saved Jobs');
                    this.getSavedAdvertList(); // refresh
                } else {
                    this.toastr.error('Job not Saved');
                }
            },
            err => console.log(err));
    }

    private removeSavedJob(jobIdOrGuid: string): void {
        for (const item of this.savedJobs) {
            if (item.AdvertId.toString() === jobIdOrGuid || item.AdvertGuid === jobIdOrGuid) {
                this.candidateService.removeSavedJob(item.CandidateSavedJobId).then(
                    res => {
                        if (res) {
                            this.toastr.info('Job removed from Saved Jobs');
                            this.getSavedAdvertList(); // refresh
                        } else {
                            this.toastr.error('Job not Removed');
                        }
                    },
                ).catch(err => console.log(err));
                break;
            }
        }
    }

    private checkIfSaved(jobIdOrGuid: string): boolean {
        let flag = false;
        for (const item of this.savedJobs) {
            if (item.AdvertId.toString() === jobIdOrGuid || item.AdvertGuid === jobIdOrGuid) {
                flag = true;
                break;
            }
        }
        this.isJobSaved = flag;
        return this.isJobSaved;
    }

    private getSavedJobs(): void {
        this.candidateService.listCandidateSavedJobs()
            .then(r => {
                this.savedJobs = r;
            })
            .catch(err => console.log('Error retrieving saved jobs:' + err));
    }

    private getSavedAdvertList(): void {
        this.candidateService.listCandidateSavedJobs().then(result => {
            this.savedJobs = result;
            this.checkIfSaved(this.jobIdOrGuid);
        });
    }
}
