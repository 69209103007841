import { Injectable } from '@angular/core';

import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { IJobCategory } from '../models/advert.model';

@Injectable()
export class JobService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService
    ) {
        super(httpClientHelper, authenticationService, helper);
    }

    getJobCategories(): Promise<IJobCategory[]> {
        return this.httpGetWithoutParams<IJobCategory[]>('Job.Categories');
    }
}
