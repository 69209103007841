import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

@Injectable()

export class PreviousRouteService {

    private history: string[] = [];

    constructor(private router: Router) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
            this.history = [...this.history, urlAfterRedirects];
        });
    }

    getPreviousUrl() {
        return this.history[this.history.length - 2] || '/home';
    }
}
