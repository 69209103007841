import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(originalText: string, maxNumChars: number, trailingText: string = '...'): string {
        originalText = originalText.trim();
        if (originalText.length <= maxNumChars) {
            return originalText;
        } else {
            const startPosition = 0;
            const trimmedString = originalText.substr(startPosition, maxNumChars);
            const endPosition = Math.min(trimmedString.length, trimmedString.lastIndexOf(' '));

            return trimmedString.substr(startPosition, endPosition) + trailingText;
        }
    }
}
