import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject } from 'rxjs';

import { AdvertQuery, SearchTerm, PagingDictionary } from '../../../models/advertQuery.model';
import { Nullable, EAdvertSortOrder, LocationData } from '../../../datatypes/misc';
import { AdvertQueryResponse } from 'src/app/models/advert.model';

@Injectable({providedIn: 'root'})
export class SearchFormSharedService {

    public data: Nullable<AdvertQuery>;

    private showDistanceTab = new BehaviorSubject(false);
    private showRelevanceTab = new BehaviorSubject(false);
    private resetFilterOnNewSearch = new BehaviorSubject(false);
    private searchTermSubject = new BehaviorSubject<SearchTerm[]>([]);
    private locationDataSubject = new BehaviorSubject<LocationData>(null);
    private sortOrderSubject = new BehaviorSubject<EAdvertSortOrder>(EAdvertSortOrder.PublishDate);
    private pageNumberSubject = new BehaviorSubject<PagingDictionary>(new PagingDictionary);
    private searchResults = new BehaviorSubject<AdvertQueryResponse>(new AdvertQueryResponse());

    constructor(
        private router: Router,
    ) { }

    setData(query: AdvertQuery): void {
        this.data = query;
    }

    getData(): Nullable<AdvertQuery> {
        const result = this.data;
        this.data = null;
        return result;
    }

    getAllJobs() {
        this.router.navigate(['/search']);
    }

    getDistanceTabView(): Observable<boolean> {
        return this.showDistanceTab.asObservable();
    }

    getRelevanceTabView(): Observable<boolean> {
        return this.showRelevanceTab.asObservable();
    }

    setDistanceTabView(val: boolean) {
        this.showDistanceTab.next(val);
    }

    setRelevanceTabView(val: boolean) {
        this.showRelevanceTab.next(val);
    }

    getResetFilterOnNewSearch(): Observable<boolean> {
        return this.resetFilterOnNewSearch.asObservable();
    }

    setResetFilterOnNewSearch(val: boolean) {
        this.resetFilterOnNewSearch.next(val);
    }

    getSearchTermValue(): SearchTerm[] {
        return this.searchTermSubject.value;
    }

    setSearchTerm(val: SearchTerm[]) {
        this.searchTermSubject.next(val);
    }

    getSortOrderValue(): EAdvertSortOrder {
        return this.sortOrderSubject.value;
    }

    setSortOrder(val: EAdvertSortOrder) {
        this.sortOrderSubject.next(val);
    }

    getPagingDictionaryData() {
        return this.pageNumberSubject.value;
    }

    setPagingDictionary(val: PagingDictionary) {
        this.pageNumberSubject.next(val);
    }

    getLocationDataValue() {
        return this.locationDataSubject.value;
    }

    setLocationData(val: LocationData) {
        this.locationDataSubject.next(val);
    }

    getSearchResults() {
        return this.searchResults.value;
    }

    setSearchResults(res: AdvertQueryResponse) {
        this.searchResults.next(res);
    }

    resetSearchCriteria() {
        this.setLocationData(null);
        this.setSearchTerm([]);
        this.setPagingDictionary(new PagingDictionary);
        this.setSortOrder(EAdvertSortOrder.PublishDate);
        this.setData(null);
    }
}
