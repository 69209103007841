import { OptionalFieldSettings, FormSettings } from '../../../models/optionalFieldSettings.model';
import { LabelHelper } from '../../../helpers/label.helper';
import { CandidateFields } from '../../../datatypes/fields/candidate-fields.constants';
import { CommonFields } from '../../../datatypes/fields/common-fields.constants';
import { Constants } from '../../../datatypes/constants';

export class FormSettingsHelperAbstractComponent {

    public formSettings: FormSettings;
    public candidateFields = CandidateFields;
    public commonFields = CommonFields;

    public dropdownInputDefaultSelect = Constants.dropdownInputDefaultSelect;


    constructor()
    {
        
    }

    public getLabel(key: string, childKey: string = ''): string {
        let label = childKey === '' ? LabelHelper.getLabel(key) : LabelHelper.getLabel(childKey);
        if (this.isRequired(key, childKey)) {
            label = label + LabelHelper.mandatoryFieldSymbol;
        }
        return label;
    }

    public getCommPreferencesLabel(key: string, childKey: string = ''): string {
        let label = childKey === '' ? LabelHelper.getLabel(key) : LabelHelper.getLabel(childKey);
        label = `I would like to receive ${label}`;
        if (this.isRequired(key, childKey)) {
            label = label + LabelHelper.mandatoryFieldSymbol;
        }
        return label;
    }

    public isRequiredCommPreferences(fieldName: string): boolean {
        let field = LabelHelper.getKeyByValue(fieldName);
        return this.isRequired(field);
     }

    public isVisible(fieldName: string, childKey: string = ''): boolean {
        let field = this.getOptionalField(fieldName, childKey);
        if (!!field) {
            return field.Visible;
        }
        return false;
    }

    public isRequired(fieldName: string, childKey: string = ''): boolean {
        let field = this.getOptionalField(fieldName, childKey);
        if (!!field) {
            return field.Required;
        }
        return false;
    }

    private getOptionalField(fieldName: string, childKey: string): OptionalFieldSettings {
        if (!!this.formSettings) {
            let field = this.formSettings.OptionalFields.find(f => f.FieldName === fieldName);
            if (!!field) {
                if (childKey !== '') {
                    return field.ChildFields?.find(c => c.FieldName === childKey);
                }
                return field;
            }
        }
        return null;
    }
   
}