import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { CorporateConfig } from '../../../corporate.config';
import { HelpersService } from '../../../helpers/helpers.service';

@Component({
    selector: 'cookie-policy',
    templateUrl: './cookies.component.html'
})
export class CookieComponent implements OnInit {

    public fragment: string;
    public PageContent = '';

    constructor(
        private router: Router,
        private helpersService: HelpersService,
        private metaService: Meta,
        private titleService: Title
    ) { 
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.cookiePolicyTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.cookiePolicyDescription});
    }

    ngOnInit() {
        this.PageContent = this.helpersService.getPageContent(CorporateConfig.cookiePage);

        //this.router.events.subscribe(s => {
        //    if (s instanceof NavigationEnd) {
        //        const tree = this.router.parseUrl(this.router.url);

        //        if (tree.fragment) {
        //            const element = document.querySelector('#' + tree.fragment);
        //            if (element) { element.scrollIntoView(true); }
        //        } else {
        //            window.scrollTo(0, 0);
        //        }
        //    }
        //});
    }

    //navigateTo(location: string) {
    //    window.location.hash = '';
    //    window.location.hash = location;
    //}
}
