import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { CorporateConfig } from 'src/app/corporate.config';
import { HelpersService } from '../../../helpers/helpers.service';

@Component({
    selector: 'privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

    public fragment: string;
    public privacyPolicyPageContent = '';

    constructor(
        private router: Router,
        private helpersService: HelpersService,
        private metaService: Meta,
        private titleService: Title
    ) { 
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.privacyPolicyTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.privacyPolicyDecription});
    }

    ngOnInit() {
        this.privacyPolicyPageContent = this.helpersService.getPrivacyPolicyPageContent();

        //this.router.events.subscribe(s => {
        //    if (s instanceof NavigationEnd) {
        //        const tree = this.router.parseUrl(this.router.url);

        //        if (tree.fragment) {
        //            const element = document.querySelector('#' + tree.fragment);
        //            if (element) { element.scrollIntoView(true); }
        //        } else {
        //            window.scrollTo(0, 0);
        //        }
        //    }
        //});
    }

    //navigateTo(location: string) {
    //    window.location.hash = '';
    //    window.location.hash = location;
    //}
}
