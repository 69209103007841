import { Component, Input, ViewChildren, QueryList, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { Candidate } from '../../../../models/candidate.model';
import { NgModel, NgForm } from '@angular/forms';
import { HelpersService } from '../../../../helpers/helpers.service';
import { ConstantsService } from '../../../../services/constants.service'
import { FormSettings } from '../../../../models/optionalFieldSettings.model';
import { FormSettingsHelperAbstractComponent } from '../form-settings-helper.abstract.component';
import { CandidateFields } from '../../../../datatypes/fields/candidate-fields.constants';
import { Dictionary } from '../../../../helpers/Dictionary';
import { HttpClientHelper } from '../../../../helpers/HttpClientHelper';

@Component({
    selector: 'employment',
    templateUrl: './employment.component.html',
    styleUrls: ['./employment.component.css']
})

export class EmploymentComponent extends FormSettingsHelperAbstractComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChildren(NgModel) controls: QueryList<NgModel>;

    @Input()
    model: Candidate;

    @Input()
    formSettings: FormSettings;

    @Input()
    public submited: boolean;

    @Output()
    onComponentChange = new EventEmitter<boolean>();

    isCurrentEmployerOk: boolean;
    isCurrentJobTitleOk: boolean;
    isNoticePeriodOk: boolean;

    constructor(protected httpClientHelper: HttpClientHelper,
        protected constantService: ConstantsService,
                protected parentForm: NgForm,
                protected helpersService: HelpersService) {
        super();
    }

    ngOnInit() {

        this.validateAllFields();


    }


    ngAfterViewInit() {
        this.controls.forEach((control: NgModel) => {
            this.parentForm.addControl(control);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.formSettings) {
            this.validateAllFields();
        }
    }


    isCurrentEmployer(): boolean {
        this.isCurrentEmployerOk = !this.isRequired(CandidateFields.currentEmployer) || !!this.model.Personal.CurrentEmployer;
        this.isComponentValid();
        return this.isCurrentEmployerOk;
    }

    isCurrentJobTitle(): boolean {
        this.isCurrentJobTitleOk = !this.isRequired(CandidateFields.currentJobTitle) || !!this.model.Personal.CurrentJobTitle;
        this.isComponentValid();
        return this.isCurrentJobTitleOk;
    }

    isValidateNoticePeriod(): boolean {
        this.isNoticePeriodOk = !this.isRequired(CandidateFields.noticePeriod) || !!this.model.Personal.NoticePeriod;
        this.isComponentValid();
        return this.isNoticePeriodOk;
    }


    isComponentValid() {
        let validators = new Dictionary([
            { key: 'Current Employer', value: this.isCurrentEmployerOk },
            { key: 'Job Title', value: this.isCurrentJobTitleOk },
            { key: 'Notice Period', value: this.isNoticePeriodOk }
        ]);

        for (let key in validators) {
            if (validators[key] !== undefined) {
                if (!validators[key]) {
                    this.onComponentChange.emit(false);
                    return;
                }
            }
        }
        this.onComponentChange.emit(true);
    }

    private validateAllFields() {
        this.isCurrentEmployer();
        this.isCurrentJobTitle();
        this.isValidateNoticePeriod();
    }
}
