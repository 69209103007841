import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CorporateConfig } from 'src/app/corporate.config';

import { HelpersService } from '../../helpers/helpers.service';

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

    contactPageContent: string;
    displayContactPageTitle: boolean;

    constructor(
        private helpersService: HelpersService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.titleService.setTitle(this.helpersService.getPageContent(CorporateConfig.contactUsTitle));
        this.metaService.updateTag({name: 'description', content: CorporateConfig.contactUsDescription});
     }

    ngOnInit() {
        this.contactPageContent = this.helpersService.getContactPageContent();
        this.displayContactPageTitle = this.helpersService.getPageContent(CorporateConfig.displayContactPageTitle)?.toLowerCase() === 'true' ? true : false;
    }
}
