import { Injectable } from '@angular/core';

import { EConstantsGroups, Constant } from '../datatypes/misc';
import { HttpClientHelper } from '../helpers/HttpClientHelper';
import { AbstractService } from './abstract.service';
import { AuthenticationService } from './authentication.service';
import { HelpersService } from '../helpers/helpers.service';
import { commonListItem1 } from '../models/common-list.model';
import { TenantConstant } from '../models/tenantconstant.model';

@Injectable()
export class ConstantsService extends AbstractService {

    constructor(
        protected httpClientHelper: HttpClientHelper,
        protected authenticationService: AuthenticationService,
        protected helper: HelpersService
    ) {
        super(httpClientHelper, authenticationService, helper);
    }

    getHours(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.HOURS];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getJobTypes(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.JOB_TYPE];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getApplicationTypes(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.APPLICATION_TYPE];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getSources(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.SOURCE];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getOpportunities(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.OPPORTUNITIES];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getAvailabilityToWork(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.AVAILABILITY_TO_WORK];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getDefaultCollege(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.DEFAULT_COLLEGE];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getAdvertStatuses(): Promise<Constant[]> {
        const name = EConstantsGroups[EConstantsGroups.ADVERT_STATUS];
        return this.httpGetWithParams<Constant[]>('Constants.ListByGroupName',
            [`?groupName=${name}`]);
    }

    getIndustrySectors(): Promise<string[]> {
        return this.httpGetWithoutParams<string[]>('Constants.GetIndustrySectors');
    }


    getSkills(): Promise<commonListItem1[]> {
        return this.httpGetWithoutParams<commonListItem1[]>('Common.Skills.ListAll');
    }

    getNationalities(): Promise<commonListItem1[]> {
        return this.httpGetWithoutParams<commonListItem1[]>('Common.Nationalities.ListAll');
    }

    getQualifiacations(): Promise<commonListItem1[]> {
        return this.httpGetWithoutParams<commonListItem1[]>('Common.Qualifications.ListAll');
    }

    public getRefereeContactOption(): Promise<TenantConstant[]> {
        return this.httpGetWithoutParams<string[]>('Constants.GetRefereeContactOption');
    }
}
